<template>
    <div
        style="height:100%;"
    >
        <!-- # Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            style="height:100%;"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove"
            @touchend="handleTouchEnd"
        >
            <!-- # Attacker -->
            <Attacker
                v-if="$store.state.mobile_tab == 'attacker'"
                :defender="defender"
                @open_stat="open_stat_handler"
                @open_caculate="open_caculate_handler"
                @damage_class_updated="damage_class_update_handler"
                @attacker_updated="attacker_update_handler"
                @switch="switch_pokemon"
                @reset="reset_data"
                :reset_key="key+'mobile_attacker'"
            />

            <!-- # Defender -->
            <Defender
                v-if="$store.state.mobile_tab == 'defender'"
                :attacker="attacker"
                @open_stat="open_stat_handler"
                @open_caculate="open_caculate_handler"
                :damage_class="damage_class"
                @defender_updated="defender_update_handler"
                @switch="switch_pokemon"
                @reset="reset_data"
                :reset_key="key+'mobile_defender'"
            />

            <!-- # Attacker -->
            <SpeedAttacker
                v-if="$store.state.mobile_tab == 'speed_attacker'"
                :defender="defender"
                @open_stat="open_stat_handler"
                @open_caculate="open_caculate_handler"
                @damage_class_updated="damage_class_update_handler"
                @attacker_updated="attacker_update_handler"
                @switch="switch_pokemon"
                @reset="reset_data"
                :reset_key="key+'mobile_speed_attacker'"
            />

            <!-- # Defender -->
            <SpeedDefender
                v-if="$store.state.mobile_tab == 'speed_defender'"
                :attacker="attacker"
                @open_stat="open_stat_handler"
                @open_caculate="open_caculate_handler"
                :damage_class="damage_class"
                @defender_updated="defender_update_handler"
                @switch="switch_pokemon"
                @reset="reset_data"
                :reset_key="key+'mobile_speed_defender'"
            />

            <!-- # DocsLeft -->
            <DocsLeft
                v-show="$store.state.mobile_tab == 'docs_left'"
                :docs_pokemon="docs_pokemon"
            />

            <!-- # DocsRight -->
            <DocsRight
                v-show="$store.state.mobile_tab == 'docs_right'"
                :docs_pokemon="docs_pokemon"
            />
        </v-sheet>

        <!-- # PC (헤더) -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            height="60"
        >
            <div
                class="d-flex align-center mx-auto"
                style="width:820px; height:60px;"
            >
                <a
                    href="https://smartnuo.com"
                    class="d-flex"
                    draggable="true"
                    @dragstart="handleDragStart"
                >
                    <div
                        style="width:32px; height:32px; cursor:pointer;"
                    >
                        <v-img
                            width="32"
                            height="32"
                            src="@/assets/logos/icon.svg"
                        ></v-img>
                    </div>
                    <div
                        class="ml-1"
                        style="width:120px; height:32px; cursor:pointer;"
                    >
                        <v-img
                            width="120"
                            height="32"
                            src="@/assets/logos/text.svg"
                        ></v-img>
                    </div>
                </a>

                <v-spacer></v-spacer>

                <div
                    style="width:32px; height:32px; cursor:pointer;"
                    @click="openLink('https://chzzk.naver.com/live/6fccf2c08f2e9f801fc3152a8923e1c2')"
                >
                    <v-img
                        width="32"
                        height="32"
                        src="@/assets/header/chizizic.svg"
                    ></v-img>
                </div>
                <div
                    class="ml-2"
                    style="width:32px; height:32px; cursor:pointer;"
                    @click="openLink('https://www.youtube.com/@%EB%88%88%ED%8C%8C%ED%8B%B0')"
                >
                    <v-img
                        width="32"
                        height="32"
                        src="@/assets/header/youtube.svg"
                    ></v-img>
                </div>
                <div
                    class="ml-2"
                    style="width:32px; height:32px; cursor:pointer;"
                    @click="openLink('https://cafe.naver.com/1209sung?iframe_url=/MyCafeIntro.nhn%3Fclubid=31111679')"
                >
                    <v-img
                        width="32"
                        height="32"
                        src="@/assets/header/naver_cafe.svg"
                    ></v-img>
                </div>
            </div>
        </v-sheet>

        <!-- # PC (본문) (데미지 모드) -->
        <div
            v-if="!$vuetify.breakpoint.mobile && !$store.state.is_docs && !$store.state.is_speed"
            class="d-flex justify-space-between align-center mx-auto mt-5 pb-10"
            :style="transformStyle"
        >
            <!-- # Attacker -->
            <Attacker
                @open_stat="open_stat_handler"
                @open_caculate="open_caculate_handler"
                @damage_class_updated="damage_class_update_handler"
                @attacker_updated="attacker_update_handler"
                :defender="defender"
                :reset_key="key+'pc_attacker'"
            />

            <div
                style="width:64px;"
            >
                <!-- # 교체 -->
                <v-btn
                    depressed
                    small
                    width="64"
                    height="26"
                    class="rounded-lg"
                    style="background:#A1A4BA; font-size:14px; font-weight:300 !important;"
                    dark
                    @click="switch_pokemon()"
                >
                    교체
                </v-btn>

                <!-- # 초기화 -->
                <v-btn
                    depressed
                    small
                    width="64"
                    height="26"
                    class="rounded-lg mt-2"
                    style="background:#A1A4BA; font-size:14px; font-weight:300 !important;"
                    dark
                    @click="reset_data()"
                >
                    초기화
                </v-btn>

                <!-- # 계산 -->
                <v-btn
                    depressed
                    small
                    width="64"
                    height="26"
                    class="rounded-lg mt-2"
                    style="background:#A1A4BA; font-size:14px; font-weight:300 !important;"
                    dark
                    @click="open_caculate_handler()"
                >
                    계산
                </v-btn>
            </div>

            <!-- # Defender -->
            <Defender
                @open_stat="open_stat_handler"
                @open_caculate="open_caculate_handler"
                :damage_class="damage_class"
                @defender_updated="defender_update_handler"
                :attacker="attacker"
                :reset_key="key+'pc_defender'"
            />
        </div>

        <!-- # PC (본문) (스피드 모드) -->
        <div
            v-if="!$vuetify.breakpoint.mobile && !$store.state.is_docs && $store.state.is_speed"
            class="d-flex justify-space-between align-center mx-auto mt-5 pb-10"
            :style="transformStyle"
        >
            <!-- # SpeedAttacker -->
            <SpeedAttacker
                @open_stat="open_stat_handler"
                @open_caculate="open_caculate_handler"
                @damage_class_updated="damage_class_update_handler"
                @attacker_updated="attacker_update_handler"
                :defender="defender"
                :reset_key="key+'pc_speed_attacker'"
            />

            <div
                style="width:64px;"
            >
                <!-- # 교체 -->
                <v-btn
                    depressed
                    small
                    width="64"
                    height="26"
                    class="rounded-lg"
                    style="background:#A1A4BA; font-size:14px; font-weight:300 !important;"
                    dark
                    @click="switch_pokemon()"
                >
                    교체
                </v-btn>

                <!-- # 초기화 -->
                <v-btn
                    depressed
                    small
                    width="64"
                    height="26"
                    class="rounded-lg mt-2"
                    style="background:#A1A4BA; font-size:14px; font-weight:300 !important;"
                    dark
                    @click="reset_data()"
                >
                    초기화
                </v-btn>

                <!-- # 계산 -->
                <v-btn
                    depressed
                    small
                    width="64"
                    height="26"
                    class="rounded-lg mt-2"
                    style="background:#A1A4BA; font-size:14px; font-weight:300 !important;"
                    dark
                    @click="open_caculate_handler()"
                >
                    계산
                </v-btn>
            </div>

            <!-- # SpeedDefender -->
            <SpeedDefender
                @open_stat="open_stat_handler"
                @open_caculate="open_caculate_handler"
                :damage_class="damage_class"
                @defender_updated="defender_update_handler"
                :attacker="attacker"
                :reset_key="key+'pc_speed_defender'"
            />
        </div>

        <!-- # PC (본문) (도감) -->
        <div
            v-if="!$vuetify.breakpoint.mobile && $store.state.is_docs"
            class="d-flex justify-space-between align-start mx-auto mt-5 pb-10"
            :style="transformStyle"
        >
            <!-- # DocsLeft -->
            <DocsLeft />

            <div
                style="width:64px; position:sticky; top:40%; left:50%; transform:translateX(-50%);"
            ></div>

            <!-- # DocsRight -->
            <DocsRight />
        </div>

        <!-- # PC (푸터) -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            ref="footer"
            class="footer"
        >
            <p
                style="font-size:8px;"
                class="mb-1 grey--text text--darken-1 text-center"
            >
                Pokémon and Pokémon character names are trademarks of Nintendo.<br/>
                Pokémon content and materials are trademarks and copyrights of Nintendo or its licensors.<br/>
                All rights reserved.
            </p>
        </div>

        <!-- # 스텟 수정 (Dialog) -->
        <v-dialog
            v-model="dialog.stat"
            width="360"
            content-class="rounded-10"
        >
            <v-sheet
                :class="$vuetify.breakpoint.width < 380? 'px-2':'px-4'"
                class="rounded-10 pt-8 pb-5"
                style="position:relative;"
            >

                <v-icon
                    style="position:absolute; top:12px; right:12px;"
                    color="grey"
                    @click="dialog.stat = false"
                >
                    mdi-close
                </v-icon>

                <div
                    v-if="check_attacker_stat()"
                    style="position:relative;"
                >

                    <div
                        class="d-flex align-center"
                    >
                        <div
                            class="mb-2px"
                            style="width:48px; height:48px;"
                        >
                            <v-img
                                :src="attacker.sprite"
                            ></v-img>
                        </div>

                        <!-- # 타입 -->
                        <v-select
                            class="ml-1 rounded-lg shrink"
                            style="width:196px;"
                            label="타입"
                            placeholder="타입 선택"
                            persistent-placeholder
                            dense
                            hide-details
                            :items="type_list"
                            v-model="attacker.types"
                            outlined
                            multiple
                            chips
                            deletable-chips
                            :menu-props="{
                                offsetY: true,
                                maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                            }"
                            @change="attacker_types_updated()"
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip
                                    small
                                    class="mx-1 px-2 pr-3"
                                    color="#eee"
                                    close
                                    @click:close="removeAttackerType(index)"
                                >
                                    <span>{{ item }}</span>
                                </v-chip>
                            </template>
                        </v-select>

                        <v-icon
                            class="ml-3"
                            style="cursor:pointer;"
                            @click="loadAttacker()"
                        >
                            mdi-reload
                        </v-icon>

                        <!-- <v-icon
                            style="position:absolute; top:12px; right:12px;"
                            color="grey"
                            @click="dialog.stat = false"
                        >
                            mdi-close
                        </v-icon> -->
                    </div>

                    <!-- PC -->
                    <div
                        v-if="$vuetify.breakpoint.width > 380"
                        class="d-flex justify-space-between mt-4 center_label"
                    >
                        <!-- # 체력 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="체력"
                            dense
                            hide-details
                            v-model="attacker.stats.hp"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 공격 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="공격"
                            dense
                            hide-details
                            v-model="attacker.stats.attack"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 방어 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="방어"
                            dense
                            hide-details
                            v-model="attacker.stats.defense"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 특공 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="특공"
                            dense
                            hide-details
                            v-model="attacker.stats.special_attack"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 특방 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="특방"
                            dense
                            hide-details
                            v-model="attacker.stats.special_defense"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 스피드 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="스피드"
                            dense
                            hide-details
                            v-model="attacker.stats.speed"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>
                    </div>

                    <!-- Mobile -->
                    <div
                        v-else
                        class="d-flex justify-space-between mt-4 center_label mobile_stat"
                    >
                        <!-- # 체력 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="체력"
                            dense
                            hide-details
                            v-model="attacker.stats.hp"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 공격 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="공격"
                            dense
                            hide-details
                            v-model="attacker.stats.attack"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 방어 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="방어"
                            dense
                            hide-details
                            v-model="attacker.stats.defense"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 특공 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="특공"
                            dense
                            hide-details
                            v-model="attacker.stats.special_attack"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 특방 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="특방"
                            dense
                            hide-details
                            v-model="attacker.stats.special_defense"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 스피드 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="스피드"
                            dense
                            hide-details
                            v-model="attacker.stats.speed"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>
                    </div>
                </div>

                <!-- <v-divider v-if="defender.name == '' || attacker.name == ''" class="my-4"></v-divider> -->

                <div
                    v-if="check_defender_stat()"
                    style="position:relative;"
                    :class="check_attacker_stat()? 'mt-6':''"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <div
                            class="mb-2px"
                            style="width:48px; height:48px;"
                        >
                            <v-img
                                :src="defender.sprite"
                            ></v-img>
                        </div>

                        <!-- # 타입 -->
                        <v-select
                            class="ml-1 rounded-lg shrink"
                            style="width:196px;"
                            label="타입"
                            placeholder="타입 선택"
                            persistent-placeholder
                            dense
                            hide-details
                            :items="type_list"
                            v-model="defender.types"
                            outlined
                            multiple
                            chips
                            deletable-chips
                            :menu-props="{
                                offsetY: true,
                                maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                            }"
                            @change="defender_types_updated()"
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip
                                    small
                                    class="mx-1 px-2 pr-3"
                                    color="#eee"
                                    close
                                    @click:close="removeDefenderType(index)"
                                >
                                    <span>{{ item }}</span>
                                </v-chip>
                            </template>
                        </v-select>

                        <v-icon
                            class="ml-3"
                            style="cursor:pointer;"
                            @click="loadDefender()"
                        >
                            mdi-reload
                        </v-icon>
                    </div>

                    <!-- PC -->
                    <div
                        v-if="$vuetify.breakpoint.width > 380"
                        class="d-flex justify-space-between mt-4 center_label"
                    >
                        <!-- # 체력 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="체력"
                            dense
                            hide-details
                            v-model="defender.stats.hp"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 공격 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="공격"
                            dense
                            hide-details
                            v-model="defender.stats.attack"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 방어 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="방어"
                            dense
                            hide-details
                            v-model="defender.stats.defense"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 특공 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="특공"
                            dense
                            hide-details
                            v-model="defender.stats.special_attack"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 특방 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="특방"
                            dense
                            hide-details
                            v-model="defender.stats.special_defense"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 스피드 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:50px;"
                            label="스피드"
                            dense
                            hide-details
                            v-model="defender.stats.speed"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>
                    </div>

                    <!-- Mobile -->
                    <div
                        v-else
                        class="d-flex justify-space-between mt-4 center_label mobile_stat"
                    >
                        <!-- # 체력 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="체력"
                            dense
                            hide-details
                            v-model="defender.stats.hp"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 공격 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="공격"
                            dense
                            hide-details
                            v-model="defender.stats.attack"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 방어 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="방어"
                            dense
                            hide-details
                            v-model="defender.stats.defense"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 특공 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="특공"
                            dense
                            hide-details
                            v-model="defender.stats.special_attack"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 특방 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="특방"
                            dense
                            hide-details
                            v-model="defender.stats.special_defense"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>

                        <!-- # 스피드 -->
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:46px;"
                            label="스피드"
                            dense
                            hide-details
                            v-model="defender.stats.speed"
                            outlined
                            type="number"
                            min="0"
                            max="999"
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>
                    </div>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- # 계산 (dialog) -->
        <v-dialog
            v-model="dialog.caculate"
            width="360"
            content-class="rounded-10"
        >
            <v-sheet
                v-if="attacker.name && defender.name"
                class="rounded-10 pa-4 pb-6"
                style="position:relative;"
            >
                <v-icon
                    style="position:absolute; top:12px; right:12px;"
                    color="grey"
                    @click="dialog.caculate = false"
                >
                    mdi-close
                </v-icon>

                <div
                    class="d-flex align-center mb-1"
                >
                    <div
                        style="width:20px; height:20px;"
                        class="mb-2px"
                    >
                        <v-img
                            src="@/assets/icons/attacker.png"
                        ></v-img>
                    </div>
                    <p
                        style="color:#E9C46A; font-weight:500; font-size:20px;"
                        class="ml-2 mb-0"
                    >
                        공격측
                    </p>
                </div>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    {{translatePokemon(attacker.name)}}
                    <span style="font-weight:400; margin-left:4px;">LV {{attacker.level}}</span>
                    <span v-if="attacker.stellar" style="font-weight:400; margin-left:8px;" class="primary--text">스텔라</span>
                    <span v-if="attacker.terastal" style="font-weight:400; margin-left:8px;" class="primary--text">테라스탈</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    공격수치 <span style="font-weight:400; margin-left:4px;">EV {{attacker.effort}} IV {{attacker.individual_value}} 실수값 {{attack_modifier}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    성격 <span style="font-weight:400; margin-left:4px;">x {{attacker.personality.toFixed(1)}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    기술 <span style="font-weight:400; margin-left:4px;">{{translateMove(attacker.move.name)}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    결정력 <span style="margin-left:4px; color:#E4007F;">{{potential}}</span>
                </p>

                <v-divider v-if="attacker.ability || attacker.equipment" class="my-2"></v-divider>

                <p
                    v-if="attacker.ability"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    특성 <span style="font-weight:400; margin-left:4px;">{{attacker.ability}}</span>
                </p>

                <p
                    v-if="attacker.equipment"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    도구 <span style="font-weight:400; margin-left:4px;">{{attacker.equipment}}</span>
                </p>

                <v-divider v-if="
                        attacker.cc
                        || attacker.support.gebso
                        || attacker.support.charge
                        || attacker.support.helper
                        || attacker.support.battery
                        || attacker.support.power_spot
                        || attacker.support.friend_guard
                        || attacker.support.flower_gift
                        || attacker.support.fairy_aura
                        || attacker.support.dark_aura
                        || attacker.support.aura_break
                        || attacker.support.disaster_plate
                        || attacker.support.disaster_sword
                        || attacker.support.disaster_dish
                        || attacker.support.disaster_orb
                        || attacker.support.steel_spirit
                    " class="my-2"></v-divider>

                <p
                    v-if="attacker.cc"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    상태이상 <span style="font-weight:400; margin-left:4px;">{{attacker.cc}}</span>
                </p>

                <div
                    v-if="
                        attacker.support.gebso
                        || attacker.support.charge
                        || attacker.support.helper
                        || attacker.support.battery
                        || attacker.support.power_spot
                        || attacker.support.friend_guard
                        || attacker.support.flower_gift
                        || attacker.support.fairy_aura
                        || attacker.support.dark_aura
                        || attacker.support.aura_break
                        || attacker.support.disaster_plate
                        || attacker.support.disaster_sword
                        || attacker.support.disaster_dish
                        || attacker.support.disaster_orb
                        || attacker.support.steel_spirit
                    "
                    style="font-weight:500; font-size:14px;"
                    class="mb-0 d-flex"
                >
                    <div
                        style="width:32px; flex-shrink: 0;"
                    >
                        보정
                    </div>
                    <div class="d-flex flex-wrap grow" style="width:260px;">
                        <span v-if="attacker.support.gebso" style="font-weight:400; margin-left:4px;">급소</span>
                        <span v-if="attacker.support.charge" style="font-weight:400; margin-left:4px;">충전</span>
                        <span v-if="attacker.support.helper" style="font-weight:400; margin-left:4px;">도우미</span>
                        <span v-if="attacker.support.battery" style="font-weight:400; margin-left:4px;">배터리</span>
                        <span v-if="attacker.support.power_spot" style="font-weight:400; margin-left:4px;">파워스톤</span>
                        <span v-if="attacker.support.friend_guard" style="font-weight:400; margin-left:4px;">프렌드가드</span>
                        <span v-if="attacker.support.flower_gift" style="font-weight:400; margin-left:4px;">플라워기프트</span>
                        <span v-if="attacker.support.fairy_aura" style="font-weight:400; margin-left:4px;">페어리오라</span>
                        <span v-if="attacker.support.dark_aura" style="font-weight:400; margin-left:4px;">다크오라</span>
                        <span v-if="attacker.support.aura_break" style="font-weight:400; margin-left:4px;">오라브레이크</span>
                        <span v-if="attacker.support.disaster_plate" style="font-weight:400; margin-left:4px;">재앙의목간</span>
                        <span v-if="attacker.support.disaster_sword" style="font-weight:400; margin-left:4px;">재앙의검</span>
                        <span v-if="attacker.support.disaster_dish" style="font-weight:400; margin-left:4px;">재앙의그릇</span>
                        <span v-if="attacker.support.disaster_orb" style="font-weight:400; margin-left:4px;">재앙의구슬</span>
                        <span v-if="attacker.support.steel_spirit" style="font-weight:400; margin-left:4px;">강철정신</span>
                    </div>
                </div>

                <div
                    class="d-flex align-center mb-1 mt-5"
                >
                    <div
                        style="width:20px; height:20px;"
                        class="mb-2px"
                    >
                        <v-img
                            src="@/assets/icons/defender.png"
                        ></v-img>
                    </div>
                    <p
                        style="color:#8FC31F; font-weight:500; font-size:20px;"
                        class="ml-2 mb-0"
                    >
                        수비측
                    </p>
                </div>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    {{translatePokemon(defender.name)}} <span style="font-weight:400; margin-left:4px;">LV {{defender.level}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    체력수치 <span style="font-weight:400; margin-left:4px;">EV {{defender.effort_for_hp}} IV {{defender.individual_value_for_hp}} 실수값 {{hp_modifier}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    방어수치 <span style="font-weight:400; margin-left:4px;">EV {{defender.effort_for_defend}} IV {{defender.individual_value_for_defend}} 실수값 {{defend_modifier}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    성격 <span style="font-weight:400; margin-left:4px;">x {{defender.personality.toFixed(1)}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    내구력 <span style="margin-left:4px; color:#E4007F;">{{durability}}</span>
                </p>

                <v-divider class="my-2" v-if="defender.ability || defender.equipment || defender.support.reflect || defender.support.light_screen || defender.support.aurora_veil"></v-divider>

                <p
                    v-if="defender.ability"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    특성 <span style="font-weight:400; margin-left:4px;">{{defender.ability}}</span>
                </p>

                <p
                    v-if="defender.equipment"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    도구 <span style="font-weight:400; margin-left:4px;">{{defender.equipment}}</span>
                </p>

                <p
                    v-if="defender.support.reflect || defender.support.light_screen || defender.support.aurora_veil"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    보정
                    <span v-if="defender.support.reflect" style="font-weight:400; margin-left:4px;">리플렉터</span>
                    <span v-if="defender.support.light_screen" style="font-weight:400; margin-left:4px;">빛의장막</span>
                    <span v-if="defender.support.aurora_veil" style="font-weight:400; margin-left:4px;">오로라베일</span>
                </p>

                <v-divider v-if="attacker.weather || attacker.field" class="my-2"></v-divider>

                <p>
                    <span
                        v-if="attacker.weather"
                        style="font-weight:500; font-size:14px;"
                        class="mb-0"
                    >
                        날씨 <span style="font-weight:400; margin-left:4px;">{{attacker.weather}}</span>
                    </span>

                    <span
                        v-if="attacker.field"
                        style="font-weight:500; font-size:14px;"
                        class="mb-0 ml-10"
                    >
                        필드 <span style="font-weight:400; margin-left:4px;">{{attacker.field}}</span>
                    </span>
                </p>

                <p
                    style="font-weight:600; font-size:16px;"
                    class="mb-0 mt-6"
                >
                    {{final_damage(85)}} ~ {{final_damage(100)}} ({{((final_damage(85) / hp_modifier).toFixed(3) * 100).toFixed(1)}}% ~ {{((final_damage(100) / hp_modifier).toFixed(3) * 100).toFixed(1)}}%)
                </p>

                <p
                    style="font-weight:500; font-size:10px; color:#666; letter-spacing:-0.5px;"
                    class="mb-2"
                >
                    (
                        {{final_damage(85)}},
                        {{final_damage(86)}},
                        {{final_damage(87)}},
                        {{final_damage(88)}},
                        {{final_damage(89)}},
                        {{final_damage(90)}},
                        {{final_damage(91)}},
                        {{final_damage(92)}},
                        {{final_damage(93)}},
                        {{final_damage(94)}},
                        {{final_damage(95)}},
                        {{final_damage(96)}},
                        {{final_damage(97)}},
                        {{final_damage(98)}},
                        {{final_damage(99)}},
                        {{final_damage(100)}}
                    )
                </p>

                <p
                    style="font-weight:600; font-size:16px;"
                    class="mb-0"
                >
                    {{final_final()}}
                </p>
            </v-sheet>
        </v-dialog>

        <!-- # 계산 (dialog) (스피드) -->
        <v-dialog
            v-model="dialog.speed_caculate"
            width="360"
            content-class="rounded-10"
        >
            <v-sheet
                v-if="attacker.name && defender.name"
                class="rounded-10 pa-4 pb-6"
                style="position:relative;"
            >
                <v-icon
                    style="position:absolute; top:12px; right:12px;"
                    color="grey"
                    @click="dialog.speed_caculate = false"
                >
                    mdi-close
                </v-icon>

                <div
                    class="d-flex align-center mb-1"
                >
                    <div
                        style="width:20px; height:20px;"
                        class="mb-2px"
                    >
                        <v-img
                            src="@/assets/icons/attacker.png"
                        ></v-img>
                    </div>
                    <p
                        style="color:#E9C46A; font-weight:500; font-size:20px;"
                        class="ml-2 mb-0"
                    >
                        공격측
                    </p>
                </div>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    {{translatePokemon(attacker.name)}}
                    <span style="font-weight:400; margin-left:4px;">LV {{attacker.level}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    스피드수치 <span style="font-weight:400; margin-left:4px;">EV {{attacker.effort}} IV {{attacker.individual_value}} 실수값 {{attacker_speed_modifier}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    성격 <span style="font-weight:400; margin-left:4px;">x {{attacker.personality.toFixed(1)}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    속력 <span style="margin-left:4px; color:#E4007F;">{{attacker_speed}}</span>

                    <v-btn
                        x-small
                        depressed
                        dark
                        height="16"
                        class="ml-2 mb-1"
                        color="#a1a4ba"
                        @click="dialog.speed_comparison = true"
                    >
                        추월범위
                    </v-btn>
                </p>

                <v-divider v-if="attacker.ability || attacker.equipment" class="my-2"></v-divider>

                <p
                    v-if="attacker.ability"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    특성 <span style="font-weight:400; margin-left:4px;">{{attacker.ability}}</span>
                </p>

                <p
                    v-if="attacker.equipment"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    도구 <span style="font-weight:400; margin-left:4px;">{{attacker.equipment}}</span>
                </p>

                <v-divider v-if="
                        attacker.cc
                        || attacker.support.tailwind
                        || attacker.support.sticky_web
                    " class="my-2"></v-divider>

                <p
                    v-if="attacker.cc"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    상태이상 <span style="font-weight:400; margin-left:4px;">{{attacker.cc}}</span>
                </p>

                <div
                    v-if="
                        attacker.cc
                        || attacker.support.tailwind
                        || attacker.support.sticky_web
                    "
                    style="font-weight:500; font-size:14px;"
                    class="mb-0 d-flex"
                >
                    <div
                        style="width:32px; flex-shrink: 0;"
                    >
                        보정
                    </div>
                    <div class="d-flex flex-wrap grow" style="width:260px;">
                        <span v-if="attacker.support.tailwind" style="font-weight:400; margin-left:4px;">순풍</span>
                        <span v-if="attacker.support.sticky_web" style="font-weight:400; margin-left:4px;">끈적끈적네트</span>
                    </div>
                </div>

                <div
                    class="d-flex align-center mb-1 mt-5"
                >
                    <div
                        style="width:20px; height:20px;"
                        class="mb-2px"
                    >
                        <v-img
                            src="@/assets/icons/defender.png"
                        ></v-img>
                    </div>
                    <p
                        style="color:#8FC31F; font-weight:500; font-size:20px;"
                        class="ml-2 mb-0"
                    >
                        수비측
                    </p>
                </div>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    {{translatePokemon(defender.name)}} <span style="font-weight:400; margin-left:4px;">LV {{defender.level}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    스피드수치 <span style="font-weight:400; margin-left:4px;">EV {{defender.effort}} IV {{defender.individual_value}} 실수값 {{defender_speed_modifier}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    성격 <span style="font-weight:400; margin-left:4px;">x {{defender.personality.toFixed(1)}}</span>
                </p>

                <p
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    속력 <span style="margin-left:4px; color:#E4007F;">{{defender_speed}}</span>

                    <v-btn
                        x-small
                        depressed
                        dark
                        height="16"
                        class="ml-2 mb-1"
                        color="#a1a4ba"
                        @click="dialog.speed_comparison = true"
                    >
                        추월범위
                    </v-btn>
                </p>

                <v-divider class="my-2" v-if="defender.ability || defender.equipment || defender.support.tailwind || defender.support.sticky_web"></v-divider>

                <p
                    v-if="defender.ability"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    특성 <span style="font-weight:400; margin-left:4px;">{{defender.ability}}</span>
                </p>

                <p
                    v-if="defender.equipment"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    도구 <span style="font-weight:400; margin-left:4px;">{{defender.equipment}}</span>
                </p>

                <p
                    v-if="defender.support.tailwind || defender.support.sticky_web"
                    style="font-weight:500; font-size:14px;"
                    class="mb-0"
                >
                    보정
                    <span v-if="defender.support.tailwind" style="font-weight:400; margin-left:4px;">순풍</span>
                    <span v-if="defender.support.sticky_web" style="font-weight:400; margin-left:4px;">끈적끈적네트</span>
                </p>

                <v-divider v-if="attacker.weather || attacker.field" class="my-2"></v-divider>

                <p>
                    <span
                        v-if="attacker.weather"
                        style="font-weight:500; font-size:14px;"
                        class="mb-0"
                    >
                        날씨 <span style="font-weight:400; margin-left:4px;">{{attacker.weather}}</span>
                    </span>

                    <span
                        v-if="attacker.field"
                        style="font-weight:500; font-size:14px;"
                        class="mb-0 ml-10"
                    >
                        필드 <span style="font-weight:400; margin-left:4px;">{{attacker.field}}</span>
                    </span>
                </p>
            </v-sheet>
        </v-dialog>

        <v-dialog
            v-model="dialog.speed_comparison"
            width="340"
            content-class="rounded-10"
        >
            <v-sheet
                class="rounded-10 pa-4 pb-6"
                style="position:relative;"
            >
                <v-icon
                    style="position:absolute; top:12px; right:12px;"
                    color="grey"
                    @click="dialog.speed_comparison = false"
                >
                    mdi-close
                </v-icon>

                <div
                    class="d-flex align-center mb-1"
                >
                    <div
                        style="width:20px; height:20px;"
                        class="mb-2px"
                    >
                        <v-img
                            src="@/assets/icons/attacker.png"
                        ></v-img>
                    </div>
                    <p
                        style="color:#E9C46A; font-weight:500; font-size:20px;"
                        class="ml-2 mb-0"
                    >
                        공격측
                    </p>
                </div>

                <div
                    style="font-weight:500; font-size:16px;"
                >
                    <div
                        v-for="item in findClosestSpeeds(attacker_speed, attacker.stats.speed, attacker.individual_value, attacker.effort, attacker.personality)"
                        :key="item"
                    >
                        ㆍ{{item}}
                    </div>
                </div>

                <div
                    class="d-flex align-center mb-1 mt-7"
                >
                    <div
                        style="width:20px; height:20px;"
                        class="mb-2px"
                    >
                        <v-img
                            src="@/assets/icons/defender.png"
                        ></v-img>
                    </div>
                    <p
                        style="color:#8FC31F; font-weight:500; font-size:20px;"
                        class="ml-2 mb-0"
                    >
                        수비측
                    </p>
                </div>

                <div
                    style="font-weight:500; font-size:16px;"
                >
                    <div
                        v-for="item in findClosestSpeeds(defender_speed, defender.stats.speed, defender.individual_value, defender.effort, defender.personality)"
                        :key="item"
                    >
                        ㆍ{{item}}
                    </div>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- # 테스트 (PC) -->
        <v-sheet
            v-if="showTestSheet && !$vuetify.breakpoint.mobile"
            style="position:absolute; right:10px; top:116px; border:2px solid #0369A1;"
            width="476"
            class="rounded-15 pa-4"
        >
            <p
                class="text-subtitle-1 font-weight-bold mb-2"
            >
                테스트
            </p>

            <v-divider class="mt-4 mb-3"></v-divider>

            <div
                v-if="attacker.name && attacker.move.name && defender.name"
            >
                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">(레벨 × 2 ÷ 5) + 2) : </span>{{(parseInt(attacker.level * 2 / 5)) + 2}}
                </v-sheet>

                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">위력 : </span>{{caculated_power}}
                </v-sheet>

                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">공격력 : </span>{{caculated_attack}}
                </v-sheet>

                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">Mod1 : </span>{{mod1}}
                </v-sheet>

                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">급소 : </span>{{gebso}}
                </v-sheet>

                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">Mod2 : </span>{{mod2}}
                </v-sheet>

                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">자속보정 : </span>{{same_type_modifier}}
                </v-sheet>

                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">타입상성1 : </span>{{type_modifier1}}
                </v-sheet>

                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">타입상성2 : </span>{{type_modifier2}}
                </v-sheet>

                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">Mod3 : </span>{{mod3}}
                </v-sheet>

                <v-sheet
                    class="py-2 mr-1 mb-1 rounded-5 px-2"
                    style="border:1px solid #81b4d0;"
                >
                    <span class="font-weight-bold primary--text mr-1">무효 : </span>{{invalidity}}
                </v-sheet>
            </div>
        </v-sheet>
    </div>
</template>
<script>
import condition from '@/data/condition.json'
import common from '@/data/common.json'
import Attacker from '@/views/Attacker'
import Defender from '@/views/Defender'
import SpeedAttacker from '@/views/SpeedAttacker'
import SpeedDefender from '@/views/SpeedDefender'
import DocsLeft from '@/views/DocsLeft'
import DocsRight from '@/views/DocsRight'
import speed from '@/data/speed.json'

export default {
    components: {
        Attacker,
        Defender,
        SpeedAttacker,
        SpeedDefender,
        DocsLeft,
        DocsRight
    },

    data: () => ({
        // 타입 목록
        type_list: common.type_list,

        // Defender > 상태이상
        stat_list: speed.stat_list,

        // Attacker > 기술
        move_list: [],

        // 펀치기술
        punch_list: condition.punch_list,

        // 반동데미지 기술
        rebound_list: condition.rebound_list,

        // 접촉기술 > 물리 기술이지만 접촉기술이 아닌 경우
        touch_but_no_physical_list: condition.touch_but_no_physical_list,

        // 접촉기술 > 특수기술이지만 접촉 기술인 경우
        touch_but_special_list: condition.touch_but_special_list,

        // 베기 기술
        cut_list: condition.cut_list,

        // 무는 기술
        bite_list: condition.bite_list,

        // 소리 기술
        sound_list: condition.sound_list,

        // 파동 기술
        wave_list: condition.wave_list,

        // 바람 기술
        wind_list: condition.wind_list,

        // 구슬/폭탄 기술
        bomb_list: condition.bomb_list,

        // 교체 기술
        switch_list: condition.switch_list,

        // 자폭 기술
        suicide_list: condition.suicide_list,

        // 선공 기술
        strike_first_list: condition.strike_first_list,

        damage_class: "physical",

        attacker: {
            stats: {},
            support: {}
        },
        defender: {
            stats: {},
            support: {}
        },

        dialog: {
            stat: false,
            caculate: false,
            speed_caculate: false,
            speed_comparison: false,
        },

        showTestSheet: false,

        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,

        startX: 0,
        endX: 0,

        startY: 0,
        endY: 0,

        start_scroll_y: 0,

        // # 초기 상태 정의
        initialState : {
            // # Attacker
            attacker: {
                // 이름
                name: "",

                // 이미지
                sprite: null,

                // 타입
                types: [],

                // 스탯
                stats: {
                    // H
                    hp: 0,

                    // A
                    attack: 0,

                    // B
                    defense: 0,

                    // C
                    special_attack: 0,

                    // D
                    special_defense: 0,

                    // S
                    speed: 0,
                },

                // 테라스탈
                terastal: false,

                // 스텔라
                stellar: false,

                // 기술
                move: {
                    // 기술명
                    name: "",

                    // 기술 타입
                    type: "",

                    // 위력
                    power: 0,

                    // 데미지 유형
                    damage_class: ""
                },

                // 노력치
                effort: 252,

                // 개체값
                individual_value: 31,

                // 랭크업
                rankup: 0,

                // 공격력 (기술의 damage_class 따라 스탯 attack, special_attack를 대입)
                damage: 0,

                // 성격 보정
                personality: 1.0,

                // 레벨
                level: 50,

                // 배수
                multiple: 1,

                // 특성
                ability: "",

                // 도구
                equipment: "",

                // 상태이상 (없음 value 값 임시 지정)
                cc: "",

                // 날씨
                weather: "",

                // 필드
                field: "",

                // 서포트
                support: {
                    // 급소
                    gebso: false,

                    // 충전
                    charge: false,

                    // 도우미
                    helper: false,

                    // 배터리
                    battery: false,

                    // 파워스톤
                    power_spot: false,

                    // 프렌드가드
                    friend_guard: false,

                    // 플라워기프트
                    flower_gift: false,

                    // 페어리오라
                    fairy_aura: false,

                    // 다크오라
                    dark_aura: false,

                    // 오라브레이크
                    aura_break: false,

                    // 재앙의목간
                    disaster_plate: false,

                    // 재앙의검
                    disaster_sword: false,

                    // 재앙의그릇
                    disaster_dish: false,

                    // 재앙의구슬
                    disaster_orb: false,

                    // 강철정신
                    steel_spirit: false,
                }
            },

            // # Defender
            defender: {
                // 이름
                name: "",

                // 이미지
                sprite: null,

                // 타입
                types: [],

                // 스탯
                stats: {
                    // H
                    hp: 0,

                    // A
                    attack: 0,

                    // B
                    defense: 0,

                    // C
                    special_attack: 0,

                    // D
                    special_defense: 0,

                    // S
                    speed: 0,
                },

                // 노력치
                effort_for_hp: 0,
                effort_for_defend: 0,

                // 개체값
                individual_value_for_hp: 31,
                individual_value_for_defend: 31,

                // 랭크업
                rankup: 0,

                // 성격 보정
                personality: 1.0,

                // 레벨
                level: 50,

                // 배수
                multiple: 1,

                // 특성
                ability: "",

                // 도구
                equipment: "",

                // 서포트
                support: {
                    // 리플렉터
                    reflect: false,

                    // 빛의장막 
                    light_screen: false,

                    // 오로라베일
                    aurora_veil: false,
                }
            },
        },

        key: 0,

        caculated_stat_list: [],

        docs_pokemon: "",
    }),

    computed: {
        // 위력
        caculated_power(){
            let power = 1
            power = this.attacker.move.power

            // # 위력
            // 도우미
            // 기술위력
            // 도구보정
            // 충전
            // 특성보정
            // 상대특성보정
            // 서포트
            
            // # 도우미
            if(this.attacker.support.helper){
                power = power * 1.5
            }

            // # 기술위력 (input 값)

            // # 도구 보정
            if(this.attacker.equipment == "힘의머리띠" && this.damage_class == "physical"){
                power = power * 1.1
            }
            else if(this.attacker.equipment == "박식안경" && this.damage_class != "physical"){
                power = power * 1.1
            }
            else if(this.attacker.equipment == "실크스카프" && this.attacker.move.type == "노말"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "목탄" && this.attacker.move.type == "불꽃"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "신비의물방울" && this.attacker.move.type == "물"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "기적의씨" && this.attacker.move.type == "풀"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "자석" && this.attacker.move.type == "전기"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "녹지않는얼음" && this.attacker.move.type == "얼음"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "검은띠" && this.attacker.move.type == "격투"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "독바늘" && this.attacker.move.type == "독"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "부드러운모래" && this.attacker.move.type == "땅"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "예리한부리" && this.attacker.move.type == "비행"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "휘어진스푼" && this.attacker.move.type == "에스퍼"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "은빛가루" && this.attacker.move.type == "벌레"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "딱딱한돌" && this.attacker.move.type == "바위"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "저주의부적" && this.attacker.move.type == "고스트"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "용의이빨" && this.attacker.move.type == "드래곤"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "검은안경" && this.attacker.move.type == "악"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "금속코트" && this.attacker.move.type == "강철"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "요정의깃털" && this.attacker.move.type == "페어리"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "주얼" && this.attacker.move.type == "노말"){
                power = power * 1.3
            }
            else if(this.attacker.equipment == "플레이트"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "마음의물방울" && (this.attacker.move.type == "에스퍼" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }

            else if(this.attacker.equipment == "펀치글러브" && this.punch_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.2
            }

            else if(this.attacker.equipment == "화덕의가면" && this.attacker.move.type == "불꽃"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "주춧돌의가면" && this.attacker.move.type == "바위"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "우물의가면" && this.attacker.move.type == "물"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "금강옥" && (this.attacker.move.type == "강철" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "백옥" && (this.attacker.move.type == "물" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "백금옥" && (this.attacker.move.type == "고스트" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }

            // # 충전
            if(this.attacker.support.charge && this.attacker.move.type == "전기"){
                power = power * 2
            }
            else if(this.attacker.support.charge && this.attacker.move.type == "전기" && this.attacker.ability == "전기로바꾸기"){
                power = power * 2
            }
            else if(this.attacker.support.charge  && this.attacker.move.type == "전기" && this.attacker.ability == "풍력발전"){
                power = power * 2
            }

            // console.log(this.punch_list)
            // console.log(this.translateMove(this.attacker.move.name))
            // console.log(this.punch_list.includes(this.translateMove(this.attacker.move.name)))

            // # 특성 보정
            if(this.attacker.ability == "투쟁심(성별같음)"){
                power = power * 1.25
            }
            else if(this.attacker.ability == "투쟁심(성별다름)"){
                power = power * 0.75
            }
            else if(this.attacker.ability == "이판사판" && this.rebound_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.2
            }
            else if(this.attacker.ability == "철주먹" && this.punch_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.2
            }
            else if(this.attacker.ability == "맹화" && this.attacker.move.type == "불꽃"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "급류" && this.attacker.move.type == "물"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "심록" && this.attacker.move.type == "풀"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "벌레의알림" && this.attacker.move.type == "벌레"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "테크니션" && this.attacker.move.power <= 60){
                power = power * 1.5
            }
            // 타입상성에서 처리
            // else if(
            //     this.attacker.ability == "심안"
            //     && this.defender.type.includes("고스트")
            //     && (this.attacker.move.type == "노말" || this.attacker.move.type == "격투"))
            // {
            //     // 원래 타입상성1 계산으로 0인데 이 조건 성립시 1로 변경
            // }
            // 타입상성에서 처리
            // else if(
            //     this.attacker.ability == "배짱"
            //     && this.defender.type.includes("고스트")
            //     && (this.attacker.move.type == "노말" || this.attacker.move.type == "격투"))
            // {
            //     // 원래 타입상성1 계산으로 0인데 이 조건 성립시 1로 변경
            // }
            else if(this.attacker.ability == "순수한힘" && this.damage_class == "physical"){
                power = power * 2
            }
            else if(this.attacker.ability == "천하장사" && this.damage_class == "physical"){
                power = power * 2
            }
            else if(this.attacker.ability == "강철술사" && this.attacker.move.type == "강철"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "강철정신" && this.attacker.move.type == "강철"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "노말스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "노말"(으)로 변경
            }
            else if(this.attacker.ability == "스카이스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "비행"(으)로 변경
            }
            else if(this.attacker.ability == "일렉트릭스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "전기"(으)로 변경
            }
            else if(this.attacker.ability == "프리즈스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "얼음"(으)로 변경
            }
            else if(this.attacker.ability == "페어리스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "페어리"(으)로 변경
            }
            else if(
                this.attacker.ability == "단단한발톱"
                // 접촉기술
                && (
                    (this.damage_class == "physical" && !this.touch_but_no_physical_list.includes(this.translateMove(this.attacker.move.name)))
                    || this.touch_but_special_list.includes(this.translateMove(this.attacker.move.name))
                )
            ){
                power = power * 1.3
            }
            else if(this.attacker.ability == "독폭주" && this.attacker.cc == "독" && this.damage_class == "physical"){
                power = power * 1.5
            }
            else if(
                this.attacker.ability == "모래의힘"
                && this.attacker.weather == "모래바람"
                && (
                    this.attacker.move.type == "바위"
                    || this.attacker.move.type == "땅"
                    || this.attacker.move.type == "강철"
                )
            ){
                power = power * 1.3
            }
            else if(this.attacker.ability == "바위나르기" && this.attacker.move.type == "바위"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "수포" && this.attacker.move.type == "물"){
                power = power * 2
            }
            else if(this.attacker.ability == "애널라이즈"){
                power = power * 1.3
            }
            else if(this.attacker.ability == "열폭주" && this.attacker.cc == "화상" && this.damage_class == "special"){
                power = power * 1.5
                // 원래 상태이상 화상은 기술이 물리일떄 0.5 -> 열폭주는 특공이랑 상관 X로 처리하지 않음 (여러개기술 시 고려필요) (현재는 반영 X)
                // 화상(Mod 1) 0.5 값을 1로 변경
            }
            else if(this.attacker.ability == "예리함" && this.cut_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.5
            }
            else if(this.attacker.ability == "옹골찬턱" && this.bite_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.5
            }
            else if(this.attacker.ability == "용의턱" && this.attacker.move.type == "드래곤"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "우격다짐"){
                power = power * 1.3
                // 부가효과가 있는 기술이 부가 효과가 사라지고 위력 1.3 (조건 구현 X)
            }
            else if(this.attacker.ability == "잠복"){
                power = power * 2
                // 교체로 나온 상대에게 (조건 구현 X)
            }
            else if(this.attacker.ability == "타오르는불꽃" && this.attacker.move.type == "불꽃"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "트랜지스터" && this.attacker.move.type == "전기"){
                power = power * 1.3
            }
            else if(this.attacker.ability == "펑크록" && this.sound_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.3
            }
            else if(this.attacker.ability == "메가런처" && this.wave_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.5
            }
            else if(this.attacker.ability == "부자유친"){
                power = power * 1.25
            }
            else if(this.attacker.ability == "총대장 (1마리 기절)"){
                power = power * 1.1
            }
            else if(this.attacker.ability == "총대장 (2마리 기절)"){
                power = power * 1.2
            }
            else if(this.attacker.ability == "총대장 (3마리 기절)"){
                power = power * 1.3
            }
            else if(this.attacker.ability == "총대장 (4마리 기절)"){
                power = power * 1.4
            }
            else if(this.attacker.ability == "총대장 (5마리 기절)"){
                power = power * 1.5
            }

            // # 상대 특성 보정
            if(this.defender.ability == "두꺼운지방" && (this.attacker.move.type == "불꽃" || this.attacker.move.type == "얼음")){
                power = power * 0.5
            }
            else if(this.defender.ability == "내열" && this.attacker.move.type == "불꽃"){
                power = power * 0.5
            }
            else if(this.defender.ability == "수포" && this.attacker.move.type == "불꽃"){
                power = power * 0.5
            }
            else if(this.defender.ability == "건조피부" && this.attacker.move.type == "불꽃"){
                power = power * 1.25
            }
            else if(this.defender.ability == "멀티스케일"){
                power = power * 0.5
                // 방어측 체력이 100% (조건 구현 X)
            }
            else if(this.defender.ability == "스펙터가드"){
                power = power * 0.5
                // 방어측 체력이 100% (조건 구현 X)
            }
            else if(this.defender.ability == "테라셸"){
                power = power * 0.5
                // 방어측 체력이 100% (조건 구현 X)
            }
            else if(this.defender.ability == "복슬복슬"){
                // 접촉기술
                if(
                    (this.damage_class == "physical" && !this.touch_but_no_physical_list.includes(this.translateMove(this.attacker.move.name)))
                    || this.touch_but_special_list.includes(this.translateMove(this.attacker.move.name))
                ){
                    power = power * 0.5
                }
                else if(
                    this.attacker.move.type == "불꽃"
                ){
                    power = power * 2
                }
            }
            else if(this.defender.ability == "정화의소금" && this.attacker.move.type == "고스트"){
                power = power * 0.5
            }
            else if(this.defender.ability == "펑크록" && this.sound_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 0.5
            }
            else if(this.defender.ability == "퍼코트" && this.damage_class == "physical"){
                power = power * 0.5
            }
            else if(this.defender.ability == "얼음인분" && this.damage_class == "special"){
                power = power * 0.5
            }

            // # 서포트 보정
            if(this.attacker.support.battery && this.damage_class == "special"){
                power = power * 1.3
            }
            if(this.attacker.support.fairy_aura && this.attacker.move.type == "페어리"){
                if(this.attacker.support.aura_break){
                    power = power * 0.75
                }else{
                    power = power * 1.33
                }
            }
            if(this.attacker.support.dark_aura && this.attacker.move.type == "악"){
                if(this.attacker.support.aura_break){
                    power = power * 0.75
                }else{
                    power = power * 1.33
                }
            }
            if(this.attacker.support.steel_spirit && this.attacker.move.type == "강철"){
                power = power * 1.5
            }
            if(this.attacker.support.power_spot){
                power = power * 1.3
            }
            if(this.attacker.support.friend_guard){
                power = power * 0.7
            }

            // 배수를 위력 공식 내에 추가
            power = power * this.attacker.multiple

            return power
        },

        // 위력 for 결정력
        caculated_power_for_potential(){
            let power = 1
            power = this.attacker.move.power

            // # 위력
            // 도우미
            // 기술위력
            // 도구보정
            // 충전
            // 특성보정
            // 상대특성보정
            // 서포트
            
            // # 도우미
            if(this.attacker.support.helper){
                power = power * 1.5
            }

            // # 기술위력 (input 값)

            // # 도구 보정
            if(this.attacker.equipment == "힘의머리띠" && this.damage_class == "physical"){
                power = power * 1.1
            }
            else if(this.attacker.equipment == "박식안경" && this.damage_class != "physical"){
                power = power * 1.1
            }
            else if(this.attacker.equipment == "실크스카프" && this.attacker.move.type == "노말"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "목탄" && this.attacker.move.type == "불꽃"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "신비의물방울" && this.attacker.move.type == "물"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "기적의씨" && this.attacker.move.type == "풀"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "자석" && this.attacker.move.type == "전기"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "녹지않는얼음" && this.attacker.move.type == "얼음"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "검은띠" && this.attacker.move.type == "격투"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "독바늘" && this.attacker.move.type == "독"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "부드러운모래" && this.attacker.move.type == "땅"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "예리한부리" && this.attacker.move.type == "비행"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "휘어진스푼" && this.attacker.move.type == "에스퍼"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "은빛가루" && this.attacker.move.type == "벌레"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "딱딱한돌" && this.attacker.move.type == "바위"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "저주의부적" && this.attacker.move.type == "고스트"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "용의이빨" && this.attacker.move.type == "드래곤"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "검은안경" && this.attacker.move.type == "악"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "금속코트" && this.attacker.move.type == "강철"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "요정의깃털" && this.attacker.move.type == "페어리"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "주얼" && this.attacker.move.type == "노말"){
                power = power * 1.3
            }
            else if(this.attacker.equipment == "플레이트"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "마음의물방울" && (this.attacker.move.type == "에스퍼" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }

            else if(this.attacker.equipment == "펀치글러브" && this.punch_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.2
            }

            else if(this.attacker.equipment == "화덕의가면" && this.attacker.move.type == "불꽃"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "주춧돌의가면" && this.attacker.move.type == "바위"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "우물의가면" && this.attacker.move.type == "물"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "금강옥" && (this.attacker.move.type == "강철" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "백옥" && (this.attacker.move.type == "물" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "백금옥" && (this.attacker.move.type == "고스트" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }

            // # 충전
            if(this.attacker.support.charge && this.attacker.move.type == "전기"){
                power = power * 2
            }
            else if(this.attacker.support.charge && this.attacker.move.type == "전기" && this.attacker.ability == "전기로바꾸기"){
                power = power * 2
            }
            else if(this.attacker.support.charge  && this.attacker.move.type == "전기" && this.attacker.ability == "풍력발전"){
                power = power * 2
            }

            // console.log(this.punch_list)
            // console.log(this.translateMove(this.attacker.move.name))
            // console.log(this.punch_list.includes(this.translateMove(this.attacker.move.name)))

            // # 특성 보정
            if(this.attacker.ability == "투쟁심(성별같음)"){
                power = power * 1.25
            }
            else if(this.attacker.ability == "투쟁심(성별다름)"){
                power = power * 0.75
            }
            else if(this.attacker.ability == "이판사판" && this.rebound_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.2
            }
            else if(this.attacker.ability == "철주먹" && this.punch_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.2
            }
            else if(this.attacker.ability == "맹화" && this.attacker.move.type == "불꽃"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "급류" && this.attacker.move.type == "물"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "심록" && this.attacker.move.type == "풀"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "벌레의알림" && this.attacker.move.type == "벌레"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "테크니션" && this.attacker.move.power <= 60){
                power = power * 1.5
            }
            // 타입상성에서 처리
            // else if(
            //     this.attacker.ability == "심안"
            //     && this.defender.type.includes("고스트")
            //     && (this.attacker.move.type == "노말" || this.attacker.move.type == "격투"))
            // {
            //     // 원래 타입상성1 계산으로 0인데 이 조건 성립시 1로 변경
            // }
            // 타입상성에서 처리
            // else if(
            //     this.attacker.ability == "배짱"
            //     && this.defender.type.includes("고스트")
            //     && (this.attacker.move.type == "노말" || this.attacker.move.type == "격투"))
            // {
            //     // 원래 타입상성1 계산으로 0인데 이 조건 성립시 1로 변경
            // }
            else if(this.attacker.ability == "순수한힘" && this.damage_class == "physical"){
                power = power * 2
            }
            else if(this.attacker.ability == "천하장사" && this.damage_class == "physical"){
                power = power * 2
            }
            else if(this.attacker.ability == "강철술사" && this.attacker.move.type == "강철"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "강철정신" && this.attacker.move.type == "강철"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "노말스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "노말"(으)로 변경
            }
            else if(this.attacker.ability == "스카이스킨" && this.attacker.move.type == "노말"){
                power = power * 1.2
                // 선택한 기술타입을 "비행"(으)로 변경
            }
            else if(this.attacker.ability == "일렉트릭스킨" && this.attacker.move.type == "노말"){
                power = power * 1.2
                // 선택한 기술타입을 "전기"(으)로 변경
            }
            else if(this.attacker.ability == "프리즈스킨" && this.attacker.move.type == "노말"){
                power = power * 1.2
                // 선택한 기술타입을 "얼음"(으)로 변경
            }
            else if(this.attacker.ability == "페어리스킨" && this.attacker.move.type == "노말"){
                power = power * 1.2
                // 선택한 기술타입을 "페어리"(으)로 변경
            }
            else if(
                this.attacker.ability == "단단한발톱"
                // 접촉기술
                && (
                    (this.damage_class == "physical" && !this.touch_but_no_physical_list.includes(this.translateMove(this.attacker.move.name)))
                    || this.touch_but_special_list.includes(this.translateMove(this.attacker.move.name))
                )
            ){
                power = power * 1.3
            }
            else if(this.attacker.ability == "독폭주" && this.attacker.cc == "독" && this.damage_class == "physical"){
                power = power * 1.5
            }
            else if(
                this.attacker.ability == "모래의힘"
                && this.attacker.weather == "모래바람"
                && (
                    this.attacker.move.type == "바위"
                    || this.attacker.move.type == "땅"
                    || this.attacker.move.type == "강철"
                )
            ){
                power = power * 1.3
            }
            else if(this.attacker.ability == "바위나르기" && this.attacker.move.type == "바위"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "수포" && this.attacker.move.type == "물"){
                power = power * 2
            }
            else if(this.attacker.ability == "애널라이즈"){
                power = power * 1.3
            }
            else if(this.attacker.ability == "열폭주" && this.attacker.cc == "화상" && this.damage_class == "special"){
                power = power * 1.5
                // 원래 상태이상 화상은 기술이 물리일떄 0.5 -> 열폭주는 특공이랑 상관 X로 처리하지 않음 (여러개기술 시 고려필요) (현재는 반영 X)
                // 화상(Mod 1) 0.5 값을 1로 변경
            }
            else if(this.attacker.ability == "예리함" && this.cut_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.5
            }
            else if(this.attacker.ability == "옹골찬턱" && this.bite_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.5
            }
            else if(this.attacker.ability == "용의턱" && this.attacker.move.type == "드래곤"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "우격다짐"){
                power = power * 1.3
                // 부가효과가 있는 기술이 부가 효과가 사라지고 위력 1.3 (조건 구현 X)
            }
            else if(this.attacker.ability == "잠복"){
                power = power * 2
                // 교체로 나온 상대에게 (조건 구현 X)
            }
            else if(this.attacker.ability == "타오르는불꽃" && this.attacker.move.type == "불꽃"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "트랜지스터" && this.attacker.move.type == "전기"){
                power = power * 1.3
            }
            else if(this.attacker.ability == "펑크록" && this.sound_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.3
            }
            else if(this.attacker.ability == "메가런처" && this.wave_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.5
            }
            else if(this.attacker.ability == "부자유친"){
                power = power * 1.25
            }
            else if(this.attacker.ability == "총대장 (1마리 기절)"){
                power = power * 1.1
            }
            else if(this.attacker.ability == "총대장 (2마리 기절)"){
                power = power * 1.2
            }
            else if(this.attacker.ability == "총대장 (3마리 기절)"){
                power = power * 1.3
            }
            else if(this.attacker.ability == "총대장 (4마리 기절)"){
                power = power * 1.4
            }
            else if(this.attacker.ability == "총대장 (5마리 기절)"){
                power = power * 1.5
            }

            // # 서포트 보정
            if(this.attacker.support.battery && this.damage_class == "special"){
                power = power * 1.3
            }
            if(this.attacker.support.fairy_aura && this.attacker.move.type == "페어리"){
                if(this.attacker.support.aura_break){
                    power = power * 0.75
                }else{
                    power = power * 1.33
                }
            }
            if(this.attacker.support.dark_aura && this.attacker.move.type == "악"){
                if(this.attacker.support.aura_break){
                    power = power * 0.75
                }else{
                    power = power * 1.33
                }
            }
            if(this.attacker.support.steel_spirit && this.attacker.move.type == "강철"){
                power = power * 1.5
            }
            if(this.attacker.support.power_spot){
                power = power * 1.3
            }
            if(this.attacker.support.friend_guard){
                power = power * 0.7
            }

            // 배수를 위력 공식 내에 추가
            power = power * this.attacker.multiple

            return power
        },

        // 공격 실수값
        attack_modifier(){
            // (공격력 + 개체값/2 + 노력치/8 + 5) * 성격 보정
            // return Math.floor(
            //     (this.attacker.damage + this.attacker.individual_value / 2 + this.attacker.effort / 8 + 5) 
            //     * this.attacker.personality
            // )

            // [{(종족값x2)+개체값+(노력치/4)}x레벨/100+5]x성격보정
            return Math.floor(
                Math.floor(
                    (
                        this.attacker.damage * 2
                        + this.attacker.individual_value
                        + this.attacker.effort / 4
                    ) 
                    * parseInt(this.attacker.level) / 100
                    + 5
                )
                * this.attacker.personality
            )
        },

        // 공격력 (공격 실수값 X 각종 보정)
        caculated_attack(){
            let attack = 1

            // # 공격 실수값
            attack = this.attack_modifier

            // # 랭크보정
            attack = attack * this.rankup_value_attacker

            // # 특성보정
            if(this.attacker.ability == "근성" && this.attacker.cc != "" && this.damage_class == "physical"){
                attack = attack * 1.5
            }
            else if(this.attacker.ability == "의욕" && this.damage_class == "physical"){
                attack = attack * 1.5
            }
            else if(this.attacker.ability == "슬로스타트" && this.damage_class == "physical"){
                attack = attack * 0.5
            }
            else if(this.attacker.ability == "플러스" && this.damage_class == "special"){
                attack = attack * 1.5
            }
            else if(this.attacker.ability == "마이너스" && this.damage_class == "special"){
                attack = attack * 1.5
            }
            else if(this.attacker.ability == "선파워" && this.attacker.weather == "쾌청" && this.damage_class == "special"){
                attack = attack * 1.5
            }
            else if(this.attacker.ability == "무기력"){
                attack = attack * 0.5
            }
            else if(this.attacker.ability == "무아지경" && this.damage_class == "physical"){
                attack = attack * 1.5
            }
            // * 서포트인데 중간에 변경됨
            // else if(this.attacker.support.flower_gift && this.damage_class == "physical"){
            //     attack = attack * 1.5
            // }
            else if(this.attacker.ability == "고대활성" && (this.attacker.equipment == "부스트에너지" || this.attacker.weather == "쾌청")){
                attack = attack * 1.3
            }
            else if(this.attacker.ability == "쿼크차지" && (this.attacker.equipment == "부스트에너지" || this.attacker.field == "일렉트릭필드")){
                attack = attack * 1.3
            }
            else if(this.attacker.ability == "진홍빛고동" && this.damage_class == "physical"){
                attack = attack * 1.3333
                // 날씨를 "쾌청"으로 바꿈
            }
            else if(this.attacker.ability == "하드론엔진" && this.damage_class == "special"){
                attack = attack * 1.3333
                // 필드를 "일렉트릭필드"으로 바꿈
            }

            // # 상대특성보정
            if(this.defender.ability == "재앙의목간" && this.damage_class == "physical"){
                attack = attack * 0.75
            }
            else if(this.defender.ability == "재앙의그릇" && this.damage_class == "special"){
                attack = attack * 0.75
            }

            // # 도구보정
            if(this.attacker.equipment == "구애머리띠" && this.damage_class == "physical"){
                attack = attack * 1.5
            }
            else if(this.attacker.equipment == "구애안경" && this.damage_class == "special"){
                attack = attack * 1.5
            }
            else if(this.attacker.equipment == "심해의이빨" && this.translatePokemon(this.attacker.name) == "진주몽" && this.damage_class == "special"){
                attack = attack * 1.5
            }
            else if(this.attacker.equipment == "전기구슬" && this.translatePokemon(this.attacker.name) == "피카츄"){
                attack = attack * 2
            }
            else if(this.attacker.equipment == "굵은뼈" && (this.translatePokemon(this.attacker.name) == "탕구리" || this.translatePokemon(this.attacker.name) == "텅구리") && this.damage_class == "physical"){
                attack = attack * 2
            }
            else if(this.attacker.equipment == "부스트에너지"){
                attack = attack * 1
            }

            // # 서포트 보정
            if(this.attacker.support.flower_gift && this.attacker.weather == "쾌청" && this.damage_class == "physical"){
                attack = attack * 1.5
            }
            if(this.attacker.support.disaster_plate && this.damage_class == "physical"){
                attack = attack * 0.75
            }
            if(this.attacker.support.disaster_dish && this.damage_class == "special"){
                attack = attack * 0.75
            }

            return attack
        },

        // 방어력 (방어 실수값 X 각종 보정)
        caculated_defend(){
            let defend = 1

            // # 방어 실수값
            defend = this.defend_modifier

            // # 랭크보정
            defend = defend * this.rankup_value_for_defender

            // # 특성보정
            if(this.defender.ability == "이상한비늘" && this.attacker.cc && this.damage_class == "physical"){
                defend = defend * 0.75
            }
            else if(this.defender.ability == "플라워기프트" && this.attacker.cc && this.damage_class == "special"){
                defend = defend * 1.5
            }

            // # 상대특성보정
            if(this.attacker.ability == "재앙의검" && this.damage_class == "physical"){
                defend = defend * 0.75
            }
            else if(this.attacker.ability == "재앙의구슬" && this.damage_class == "special"){
                defend = defend * 0.75
            }

            // # 도구보정
            if(this.defender.equipment == "심해의비늘" && this.defender.name == "진주몽" && this.damage_class == "special"){
                defend = defend * 2
            }
            else if(this.defender.equipment == "금속파우더" && this.defender.name == "메타몽"){
                defend = defend * 2
            }
            else if(this.defender.equipment == "진화의휘석"){
                defend = defend * 1.5
            }
            else if(this.defender.equipment == "돌격조끼" && this.damage_class == "special"){
                defend = Math.floor(defend * 1.5)  // 여기서 즉시 소수점 버림
            }

            // # 날씨
            if(this.attacker.weather == "모래바람" && this.damage_class == "special" && this.defender.types.includes("바위")){
                defend = defend * 1.5
            }
            else if(this.attacker.weather == "설경" && this.damage_class == "physical" && this.defender.types.includes("얼음")){
                defend = defend * 1.5
            }

            // # 서포트
            if(this.attacker.support.disaster_sword && this.damage_class == "physical"){
                defend = defend * 0.75
            }
            else if(this.attacker.support.disaster_orb && this.damage_class == "special"){
                defend = defend * 0.75
            }
            else if(this.attacker.support.flower_gift && this.attacker.weather == "쾌청" && this.damage_class == "special"){
                defend = defend * 1.5
            }

            return defend
        },

        // 결정력
        potential(){
            return Math.floor(
                this.caculated_power_for_potential
                * this.caculated_attack
                * this.mod0
                * this.mod1_for_potential // mod1에서 리플렉터, 빛의장막, 오로라베일은 제외
                * this.same_type_modifier
                // * this.attacker.multiple
                * this.mod2
                * this.gebso
            )
        },

        // 랭크업에 따른 보정값
        rankup_value_attacker(){
            if(this.attacker.rankup == -6) return this.attacker.support.gebso? 1 : 0.25
            else if(this.attacker.rankup == -5) return this.attacker.support.gebso? 1 : 0.29
            else if(this.attacker.rankup == -4) return this.attacker.support.gebso? 1 : 0.33
            else if(this.attacker.rankup == -3) return this.attacker.support.gebso? 1 : 0.4
            else if(this.attacker.rankup == -2) return this.attacker.support.gebso? 1 : 0.5
            else if(this.attacker.rankup == -1) return this.attacker.support.gebso? 1 : 0.66
            else if(this.attacker.rankup == 0) return 1
            else if(this.attacker.rankup == 1) return 1.5
            else if(this.attacker.rankup == 2) return 2
            else if(this.attacker.rankup == 3) return 2.5
            else if(this.attacker.rankup == 4) return 3
            else if(this.attacker.rankup == 5) return 3.5
            else if(this.attacker.rankup == 6) return 4
        },

        // 자속 보정값 (공격자 포켓몬 타입과 기술 타입 일치 여부)
        same_type_modifier(){
            let same_type_value = 1

            // 포켓몬타입 == 기술타입
            if(this.attacker.types.includes(this.attacker.move.type)){
                if(this.attacker.ability == "적응력"){
                    if(this.attacker.stellar){
                        same_type_value = same_type_value * 2
                    }
                    else if(this.attacker.terastal){
                        same_type_value = same_type_value * 2.25
                    }
                    else{
                        same_type_value = same_type_value * 2
                    }
                }
                else{
                    if(this.attacker.stellar){
                        same_type_value = same_type_value * 2
                    }
                    else if(this.attacker.terastal){
                        same_type_value = same_type_value * 2
                    }
                    else{
                        same_type_value = same_type_value * 1.5
                    }
                }
            }

            // 포켓몬타입 != 기술타입
            else{
                // * 적응력 여부 상관없이 배수 같아서 통합
                if(this.attacker.stellar){
                    same_type_value = same_type_value * 1.2
                }
                else if(this.attacker.terastal){
                    same_type_value = same_type_value * 1.5
                }
            }

            return same_type_value
        },

        // 체력 실수값
        hp_modifier(){
            // (체력 종족값(H) + 개체값/2 + 노력치/8 + 10) + 레벨
            // return Math.floor(
            //     (this.defender.stats.hp + this.defender.individual_value_for_hp / 2 + this.defender.effort_for_hp / 8 + 10) 
            //     + parseInt(this.defender.level)
            // )

            // (((종족값x2)+개체값+(노력치/4)+100)x레벨/100) + 10
            return Math.floor(
                (
                    this.defender.stats.hp * 2
                    + this.defender.individual_value_for_hp
                    + this.defender.effort_for_hp / 4 + 100
                ) 
                * parseInt(this.defender.level) / 100
                + 10
            )
        },

        // 방어력
        defend() {
            // 사이코쇼크와 사이코브레이크는 특수 공격이지만 물리 방어를 참조
            if(this.attacker && this.attacker.move) {
                if (this.attacker.move.name === "psyshock" || this.attacker.move.name === "psystrike") {
                    return this.defender.stats.defense
                }
            }
            
            // 나머지는 일반적인 규칙을 따름
            return this.damage_class == "physical" 
                ? this.defender.stats.defense 
                : this.defender.stats.special_defense
        },

        // 방어 실수값
        defend_modifier(){
            // (방어 종족값(B or D) + 개체값/2 + 노력치/8 + 5) * 성격보정
            // return Math.floor(
            //     (
            //         this.defend
            //         + this.defender.individual_value_for_defend / 2
            //         + this.defender.effort_for_defend / 8
            //         + 5
            //     ) 
            //     * this.defender.personality
            // )

            // [{(종족값x2)+개체값+(노력치/4)}x레벨/100+5]x성격보정
            return Math.floor(
                Math.floor(
                    (
                        this.defend * 2
                        + this.defender.individual_value_for_defend
                        + this.defender.effort_for_defend / 4
                    ) 
                    * parseInt(this.defender.level) / 100
                    + 5
                )
                * this.defender.personality
            )
        },

        // 랭크업에 따른 보정값
        rankup_value_for_defender(){
            if(this.defender.rankup == -6) return 0.25
            else if(this.defender.rankup == -5) return 0.29
            else if(this.defender.rankup == -4) return 0.33
            else if(this.defender.rankup == -3) return 0.4
            else if(this.defender.rankup == -2) return 0.5
            else if(this.defender.rankup == -1) return 0.66
            else if(this.defender.rankup == 0) return 1
            else if(this.defender.rankup == 1) return this.attacker.support.gebso? 1 : 1.5
            else if(this.defender.rankup == 2) return this.attacker.support.gebso? 1 : 2
            else if(this.defender.rankup == 3) return this.attacker.support.gebso? 1 : 2.5
            else if(this.defender.rankup == 4) return this.attacker.support.gebso? 1 : 3
            else if(this.defender.rankup == 5) return this.attacker.support.gebso? 1 : 3.5
            else if(this.defender.rankup == 6) return this.attacker.support.gebso? 1 : 4
        },

        // # 공격 스피드 실수값
        attacker_speed_modifier(){
            // // (스피드 종족값(S) + 개체값/2 + 노력치/8 + 5) * 성격보정
            // return Math.floor(
            //     (
            //         this.attacker.stats.speed
            //         + this.attacker.individual_value / 2
            //         + this.attacker.effort / 8
            //         + 5
            //     ) 
            //     * this.attacker.personality
            // )

            // [{(스피드종족값x2)+개체값+(노력치/4)}x레벨/100+5]x성격보정
            return Math.floor(
                Math.floor(
                    (
                        (this.attacker.stats.speed * 2)
                        + this.attacker.individual_value
                        + this.attacker.effort / 4
                    )
                    * (this.attacker.level / 100) + 5
                ) 
                * this.attacker.personality
            )
        },

        // # 속력
        attacker_speed(){
            return Math.floor(
                this.attacker_speed_modifier
                * this.rankup_value_attacker
                * this.attacker_ability_mod
                // (보류) * this.opponent_abillity_mod
                * this.attacker_equipment_mod
                // (보류) * this.weather_mod
                // (보류) * this.field_mod
                * this.attacker_support_mod
                * this.attacker_cc_mod
                * this.attacker.multiple
            )
        },

        // # 도구 보정
        attacker_ability_mod(){
            let ability = 1

            if(this.attacker.ability == "곡예"){
                ability = ability * 2
            }
            else if(
                this.attacker.ability == "고대활성"
                && (
                    this.attacker.equipment == "부스트에너지"
                    ||
                    this.attacker.weather == "쾌청"
                )
            ){
                ability = ability * 1.5
            }
            else if(
                this.attacker.ability == "쿼크차지"
                && (
                    this.attacker.equipment == "부스트에너지"
                    ||
                    this.attacker.field == "일렉트릭필드"
                )
            ){
                ability = ability * 1.5
            }
            else if(this.attacker.ability == "엽록소" && this.attacker.weather == "쾌청"){
                ability = ability * 2
            }
            else if(this.attacker.ability == "쓱쓱" && this.attacker.weather == "비바라기"){
                ability = ability * 2
            }
            else if(this.attacker.ability == "모래헤치기" && this.attacker.weather == "모래바람"){
                ability = ability * 2
            }
            else if(this.attacker.ability == "눈치우기" && this.attacker.weather == "설경"){
                ability = ability * 2
            }
            else if(this.attacker.ability == "서핑테일" && this.attacker.field == "일렉트릭필드"){
                ability = ability * 2
            }
            else if(this.attacker.ability == "속보" && this.attacker.cc != ""){
                ability = ability * 1.5
            }
            else if(this.attacker.ability == "슬로스타트"){
                ability = ability * 0.5
            }

            return ability
        },

        // # 도구 보정
        attacker_equipment_mod(){
            let equipment = 1

            if(this.attacker.equipment == "구애스카프"){
                equipment = equipment * 1.5
            }
            else if(this.attacker.equipment == "검은철구"){
                equipment = equipment * 0.5
            }
            else if(this.attacker.equipment == "교정깁스"){
                equipment = equipment * 0.5
            }
            else if(this.attacker.equipment == "파워시리즈"){
                equipment = equipment * 0.5
            }

            return equipment
        },

        // # 서포트 보정
        attacker_support_mod(){
            let support = 1

            if(this.attacker.support.tailwind){
                support = support * 2
            }
            if(this.attacker.support.sticky_web){
                support = support * 0.66
            }

            return support
        },

        // # 상태이상 보정
        attacker_cc_mod(){
            let cc = 1

            // # 화상
            if(this.attacker.cc == "화상"){
                cc = cc * 0.5
            }

            return cc
        },

        // # 공격 스피드 실수값
        defender_speed_modifier(){
            // // (스피드 종족값(S) + 개체값/2 + 노력치/8 + 5) * 성격보정
            // return Math.floor(
            //     (
            //         this.defender.stats.speed
            //         + this.defender.individual_value / 2
            //         + this.defender.effort / 8
            //         + 5
            //     ) 
            //     * this.defender.personality
            // )

            // [{(스피드종족값x2)+개체값+(노력치/4)}x레벨/100+5]x성격보정
            return Math.floor(
                Math.floor(
                    (
                        (this.defender.stats.speed * 2)
                        + this.defender.individual_value
                        + this.defender.effort / 4
                    )
                    * (this.defender.level / 100) + 5
                ) 
                * this.defender.personality
            )
        },

        // # 속력
        defender_speed(){
            return Math.floor(
                this.defender_speed_modifier
                * this.rankup_value_for_defender
                * this.defender_ability_mod
                // (보류) * this.opponent_abillity_mod
                * this.defender_equipment_mod
                // (보류) * this.weather_mod
                // (보류) * this.field_mod
                * this.defender_support_mod
                * this.defender_cc_mod
                * this.defender.multiple
            )
        },

        // # 도구 보정
        defender_ability_mod(){
            let ability = 1

            if(this.defender.ability == "곡예"){
                ability = ability * 2
            }
            else if(
                this.defender.ability == "고대활성"
                && (
                    this.defender.equipment == "부스트에너지"
                    ||
                    this.attacker.weather == "쾌청"
                )
            ){
                ability = ability * 1.5
            }
            else if(
                this.defender.ability == "쿼크차지"
                && (
                    this.defender.equipment == "부스트에너지"
                    ||
                    this.defender.field == "일렉트릭필드"
                )
            ){
                ability = ability * 1.5
            }
            else if(this.defender.ability == "엽록소" && this.attacker.weather == "쾌청"){
                ability = ability * 2
            }
            else if(this.defender.ability == "쓱쓱" && this.attacker.weather == "비바라기"){
                ability = ability * 2
            }
            else if(this.defender.ability == "모래헤치기" && this.attacker.weather == "모래바람"){
                ability = ability * 2
            }
            else if(this.defender.ability == "눈치우기" && this.attacker.weather == "설경"){
                ability = ability * 2
            }
            else if(this.defender.ability == "서핑테일" && this.attacker.field == "일렉트릭필드"){
                ability = ability * 2
            }
            else if(this.defender.ability == "속보" && this.defender.cc != ""){
                ability = ability * 1.5
            }
            else if(this.defender.ability == "슬로스타트"){
                ability = ability * 0.5
            }

            return ability
        },

        // # 도구 보정
        defender_equipment_mod(){
            let equipment = 1

            if(this.defender.equipment == "구애스카프"){
                equipment = equipment * 1.5
            }
            else if(this.defender.equipment == "검은철구"){
                equipment = equipment * 0.5
            }
            else if(this.defender.equipment == "교정깁스"){
                equipment = equipment * 0.5
            }
            else if(this.defender.equipment == "파워시리즈"){
                equipment = equipment * 0.5
            }

            return equipment
        },

        // # 서포트 보정
        defender_support_mod(){
            let support = 1

            if(this.defender.support.tailwind){
                support = support * 2
            }
            if(this.defender.support.sticky_web){
                support = support * 0.66
            }

            return support
        },

        // # 상태이상 보정
        defender_cc_mod(){
            let cc = 1

            // # 화상
            if(this.defender.cc == "화상"){
                cc = cc * 0.5
            }

            return cc
        },

        // 내구력
        durability(){
            return parseInt(
                this.hp_modifier
                * Math.floor(this.caculated_defend)
                * this.defender.multiple
                / 0.411
                // * this.mod1_defender (리플렉터, 빛의장막, 오로라베일은 다시 제외)
                * this.mod3_defender
            )
        },

        // # Mod0
        mod0(){
            let mod0 = 1

            if(this.attacker.field == "미스트필드" && this.attacker.move.type == "드래곤"){
                mod0 = mod0 * 0.5
            }

            return mod0
        },

        // # Mod1_for_potential
        mod1_for_potential(){
            let mod1 = 1

            // # 화상
            if(this.attacker.cc == "화상" && this.attacker.move.damage_class == "physical"){
                if(this.attacker.ability == "근성"){
                    mod1 = mod1 * 1
                }
                else{
                    mod1 = mod1 * 0.5
                }
            }

            // # 날씨
            if(this.attacker.weather == "쾌청"){
                if(this.attacker.move.type == "불꽃"){
                    mod1 = mod1 * 1.5
                }
                else if(this.attacker.move.type == "물"){
                    mod1 = mod1 * 0.5
                }
            }
            else if(this.attacker.weather == "비바라기"){
                if(this.attacker.move.type == "물"){
                    mod1 = mod1 * 1.5
                }
                else if(this.attacker.move.type == "불꽃"){
                    mod1 = mod1 * 0.5
                }
            }

            // # 필드
            if(this.attacker.field == "그래스필드"){
                if(this.attacker.move.type == "풀"){
                    mod1 = mod1 * 1.3
                }
                if(
                    this.translateMove(this.attacker.move.name) == "땅고르기"
                    || this.translateMove(this.attacker.move.name) == "지진"
                    || this.translateMove(this.attacker.move.name) == "매그니튜드"
                ){
                    mod1 = mod1 * 0.5
                }
            }
            else if(this.attacker.field == "사이코필드" && this.attacker.move.type == "에스퍼"){
                mod1 = mod1 * 1.3
            }
            else if(this.attacker.field == "일렉트릭필드" && this.attacker.move.type == "전기"){
                mod1 = mod1 * 1.3
            }

            return mod1
        },

        // # Mod1
        mod1(){
            let mod1 = 1

            // # 화상
            if(this.attacker.cc == "화상" && this.damage_class == "physical"){
                if(this.attacker.ability == "근성"){
                    mod1 = mod1 * 1
                }
                else{
                    mod1 = mod1 * 0.5
                }
            }

            // # 리플렉터
            if(this.defender.support.reflect && !this.defender.support.aurora_veil && this.damage_class == "physical"){
                mod1 = mod1 * 0.5
            }

            // # 빛의장막
            if(this.defender.support.light_screen && !this.defender.support.aurora_veil && this.damage_class == "special"){
                mod1 = mod1 * 0.5
            }

            // # 오로라베일
            if(this.defender.support.aurora_veil && !this.defender.support.reflect && !this.defender.support.light_screen){
                mod1 = mod1 * 0.5
            }

            // # 날씨
            if(this.attacker.weather == "쾌청"){
                if(this.attacker.move.type == "불꽃"){
                    mod1 = mod1 * 1.5
                }
                else if(this.attacker.move.type == "물"){
                    mod1 = mod1 * 0.5
                }
            }
            else if(this.attacker.weather == "비바라기"){
                if(this.attacker.move.type == "물"){
                    mod1 = mod1 * 1.5
                }
                else if(this.attacker.move.type == "불꽃"){
                    mod1 = mod1 * 0.5
                }
            }

            // # 필드
            if(this.attacker.field == "그래스필드"){
                if(this.attacker.move.type == "풀"){
                    mod1 = mod1 * 1.3
                }
                if(
                    this.translateMove(this.attacker.move.name) == "땅고르기"
                    || this.translateMove(this.attacker.move.name) == "지진"
                    || this.translateMove(this.attacker.move.name) == "매그니튜드"
                ){
                    mod1 = mod1 * 0.5
                }
            }
            else if(this.attacker.field == "사이코필드" && this.attacker.move.type == "에스퍼"){
                mod1 = mod1 * 1.3
            }
            else if(this.attacker.field == "일렉트릭필드" && this.attacker.move.type == "전기"){
                mod1 = mod1 * 1.3
            }

            return mod1
        },

        // # 급소
        gebso(){
            let gebso = 1

            if(this.attacker.support.gebso){
                if(this.attacker.ability == "스나이퍼"){
                    gebso = gebso * 2.25
                }else{
                    gebso = gebso * 1.5
                }
            }

            return gebso
        },

        // # Mod2
        mod2(){
            let mod2 = 1

            // # 생명의구슬
            if(this.attacker.equipment == "생명의구슬"){
                mod2 = mod2 * 1.3
            }

            // # 메트로놈
            if(this.attacker.equipment == "메트로놈 (1회)"){
                mod2 = mod2 * 1.2
            }
            else if(this.attacker.equipment == "메트로놈 (2회)"){
                mod2 = mod2 * 1.4
            }
            else if(this.attacker.equipment == "메트로놈 (3회)"){
                mod2 = mod2 * 1.6
            }
            else if(this.attacker.equipment == "메트로놈 (4회)"){
                mod2 = mod2 * 1.8
            }
            else if(this.attacker.equipment == "메트로놈 (5회)"){
                mod2 = mod2 * 2
            }

            return mod2
        },

        // # 타입상성1
        type_modifier1(){
            let type_value1 = 1

            if(!this.defender.types){
                return 1
            }

            if(this.attacker.move.type == "노말"){
                if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "바위"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "고스트"){
                    if(this.attacker.ability == "심안" || this.attacker.ability == "배짱"){
                        type_value1 = type_value1 * 1
                    }else{
                        type_value1 = type_value1 * 0
                    }
                }

            }
            else if(this.attacker.move.type == "불꽃"){
                if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "벌레"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "얼음"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "풀"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "드래곤"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "물"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "바위"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "불꽃"){
                    type_value1 = type_value1 * 0.5
                }
            }
            else if(this.attacker.move.type == "물"){
                if(this.defender.types[0] == "땅"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "바위"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "불꽃"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "드래곤"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "물"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "풀"){
                    type_value1 = type_value1 * 0.5
                }
            }
            else if(this.attacker.move.type == "풀"){
                if(this.defender.types[0] == "물"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "땅"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "바위"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "독"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "드래곤"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "벌레"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "불꽃"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "비행"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "풀"){
                    type_value1 = type_value1 * 0.5
                }
            }
            else if(this.attacker.move.type == "전기"){
                if(this.defender.types[0] == "물"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "비행"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "드래곤"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "전기"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "풀"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "땅"){
                    type_value1 = type_value1 * 0
                }
            }
            else if(this.attacker.move.type == "얼음"){
                if(this.defender.types[0] == "드래곤"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "땅"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "비행"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "풀"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "물"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "불꽃"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "얼음"){
                    type_value1 = type_value1 * 0.5
                }
            }
            else if(this.attacker.move.type == "격투"){
                if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "노말"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "바위"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "악"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "얼음"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "독"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "벌레"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "비행"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "에스퍼"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "페어리"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "고스트"){
                    if(this.attacker.ability == "심안" || this.attacker.ability == "배짱"){
                        type_value1 = type_value1 * 1
                    }else{
                        type_value1 = type_value1 * 0
                    }
                }
            }
            else if(this.attacker.move.type == "독"){
                if(this.defender.types[0] == "페어리"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "풀"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "고스트"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "독"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "땅"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "바위"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0
                }
            }
            else if(this.attacker.move.type == "땅"){
                if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "독"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "바위"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "불꽃"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "전기"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "벌레"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "풀"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "비행"){
                    type_value1 = type_value1 * 0
                }
            }
            else if(this.attacker.move.type == "비행"){
                if(this.defender.types[0] == "풀"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "격투"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "벌레"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "전기"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "바위"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0.5
                }
            }
            else if(this.attacker.move.type == "에스퍼"){
                if(this.defender.types[0] == "격투"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "독"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "에스퍼"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "악"){
                    type_value1 = type_value1 * 0
                }
            }
            else if(this.attacker.move.type == "벌레"){
                if(this.defender.types[0] == "악"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "에스퍼"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "풀"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "격투"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "고스트"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "독"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "불꽃"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "비행"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "페어리"){
                    type_value1 = type_value1 * 0.5
                }
            }
            else if(this.attacker.move.type == "바위"){
                if(this.defender.types[0] == "벌레"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "불꽃"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "비행"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "얼음"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "격투"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "땅"){
                    type_value1 = type_value1 * 0.5
                }
            }
            else if(this.attacker.move.type == "고스트"){
                if(this.defender.types[0] == "고스트"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "에스퍼"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "악"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "노말"){
                    type_value1 = type_value1 * 0
                }
            }
            else if(this.attacker.move.type == "드래곤"){
                if(this.defender.types[0] == "드래곤"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "페어리"){
                    type_value1 = type_value1 * 0
                }
            }
            else if(this.attacker.move.type == "악"){
                if(this.defender.types[0] == "고스트"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "에스퍼"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "격투"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "악"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "페어리"){
                    type_value1 = type_value1 * 0.5
                }
            }
            else if(this.attacker.move.type == "강철"){
                if(this.defender.types[0] == "바위"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "얼음"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "페어리"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "물"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "불꽃"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "전기"){
                    type_value1 = type_value1 * 0.5
                }
            }
            else if(this.attacker.move.type == "페어리"){
                if(this.defender.types[0] == "격투"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "드래곤"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "악"){
                    type_value1 = type_value1 * 2
                }
                else if(this.defender.types[0] == "불꽃"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "독"){
                    type_value1 = type_value1 * 0.5
                }
                else if(this.defender.types[0] == "강철"){
                    type_value1 = type_value1 * 0.5
                }
            }

            return type_value1
        },

        // # 타입상성2
        type_modifier2(){
            let type_value2 = 1

            if(this.defender.types.length != 2){
                return 1
            }

            if(this.attacker.move.type == "노말"){
                if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "바위"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "고스트"){
                    if(this.attacker.ability == "심안" || this.attacker.ability == "배짱"){
                        type_value2 = type_value2 * 1
                    }else{
                        type_value2 = type_value2 * 0
                    }
                }

            }
            else if(this.attacker.move.type == "불꽃"){
                if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "벌레"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "얼음"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "풀"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "드래곤"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "물"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "바위"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "불꽃"){
                    type_value2 = type_value2 * 0.5
                }
            }
            else if(this.attacker.move.type == "물"){
                if(this.defender.types[1] == "땅"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "바위"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "불꽃"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "드래곤"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "물"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "풀"){
                    type_value2 = type_value2 * 0.5
                }
            }
            else if(this.attacker.move.type == "풀"){
                if(this.defender.types[1] == "물"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "땅"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "바위"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "독"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "드래곤"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "벌레"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "불꽃"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "비행"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "풀"){
                    type_value2 = type_value2 * 0.5
                }
            }
            else if(this.attacker.move.type == "전기"){
                if(this.defender.types[1] == "물"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "비행"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "드래곤"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "전기"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "풀"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "땅"){
                    type_value2 = type_value2 * 0
                }
            }
            else if(this.attacker.move.type == "얼음"){
                if(this.defender.types[1] == "드래곤"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "땅"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "비행"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "풀"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "물"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "불꽃"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "얼음"){
                    type_value2 = type_value2 * 0.5
                }
            }
            else if(this.attacker.move.type == "격투"){
                if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "노말"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "바위"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "악"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "얼음"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "독"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "벌레"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "비행"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "에스퍼"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "페어리"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "고스트"){
                    if(this.attacker.ability == "심안" || this.attacker.ability == "배짱"){
                        type_value2 = type_value2 * 1
                    }else{
                        type_value2 = type_value2 * 0
                    }
                }
            }
            else if(this.attacker.move.type == "독"){
                if(this.defender.types[1] == "페어리"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "풀"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "고스트"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "독"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "땅"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "바위"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0
                }
            }
            else if(this.attacker.move.type == "땅"){
                if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "독"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "바위"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "불꽃"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "전기"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "벌레"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "풀"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "비행"){
                    type_value2 = type_value2 * 0
                }
            }
            else if(this.attacker.move.type == "비행"){
                if(this.defender.types[1] == "풀"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "격투"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "벌레"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "전기"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "바위"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0.5
                }
            }
            else if(this.attacker.move.type == "에스퍼"){
                if(this.defender.types[1] == "격투"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "독"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "에스퍼"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "악"){
                    type_value2 = type_value2 * 0
                }
            }
            else if(this.attacker.move.type == "벌레"){
                if(this.defender.types[1] == "악"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "에스퍼"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "풀"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "격투"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "고스트"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "독"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "불꽃"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "비행"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "페어리"){
                    type_value2 = type_value2 * 0.5
                }
            }
            else if(this.attacker.move.type == "바위"){
                if(this.defender.types[1] == "벌레"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "불꽃"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "비행"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "얼음"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "격투"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "땅"){
                    type_value2 = type_value2 * 0.5
                }
            }
            else if(this.attacker.move.type == "고스트"){
                if(this.defender.types[1] == "고스트"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "에스퍼"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "악"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "노말"){
                    type_value2 = type_value2 * 0
                }
            }
            else if(this.attacker.move.type == "드래곤"){
                if(this.defender.types[1] == "드래곤"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "페어리"){
                    type_value2 = type_value2 * 0
                }
            }
            else if(this.attacker.move.type == "악"){
                if(this.defender.types[1] == "고스트"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "에스퍼"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "격투"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "악"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "페어리"){
                    type_value2 = type_value2 * 0.5
                }
            }
            else if(this.attacker.move.type == "강철"){
                if(this.defender.types[1] == "바위"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "얼음"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "페어리"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "물"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "불꽃"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "전기"){
                    type_value2 = type_value2 * 0.5
                }
            }
            else if(this.attacker.move.type == "페어리"){
                if(this.defender.types[1] == "격투"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "드래곤"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "악"){
                    type_value2 = type_value2 * 2
                }
                else if(this.defender.types[1] == "불꽃"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "독"){
                    type_value2 = type_value2 * 0.5
                }
                else if(this.defender.types[1] == "강철"){
                    type_value2 = type_value2 * 0.5
                }
            }

            return type_value2
        },

        // # Mod3
        mod3(){
            let mod3 = 1

            // # 특성보정
            if(this.attacker.ability == "색안경" && ((this.type_modifier1 * this.type_modifier2) <= 0.5)){
                mod3 = mod3 * 2
            }
            else if(this.attacker.ability == "브레인포스" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 1.2
            }

            // # 상대특성보정
            if(this.defender.ability == "하드록" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 0.75
            }
            else if(this.defender.ability == "필터" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 0.75
            }
            else if(this.defender.ability == "프리즘아머" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 0.75
            }

            // # 도구보정
            if(this.attacker.equipment == "달인의띠" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 1.2
            }

            // # 상대도구보정
            if(this.defender.equipment == "반감열매"){
                if((this.type_modifier1 * this.type_modifier2) >= 2){
                    if(this.defender.ability == "숙성"){
                        mod3 = mod3 * 0.5
                    }else{
                        mod3 = mod3 * 0.25
                    }
                }
            }

            // # 서포트
            if(this.attacker.support.aura_break && (this.attacker.support.pairy_aura || this.attacker.support.dark_aura)){
                mod3 = mod3 * 0.75
                // (체크필요) 페어리오라와 다크오라의 활성값을 0.75로 바꾼다
            }

            return mod3
        },

        // # 무효
        invalidity(){
            let invalidity = 1

            // # 특성보정
            if(this.defender.ability == "타오르는불꽃" && this.attacker.move.type == "불꽃"){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "노릇노릇바디" && this.attacker.move.type == "불꽃"){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "저수" && this.attacker.move.type == "물"){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "마중물" && this.attacker.move.type == "물"){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "건조피부" && this.attacker.move.type == "물"){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "축전" && this.attacker.move.type == "전기"){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "전기엔진" && this.attacker.move.type == "전기"){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "피뢰침" && this.attacker.move.type == "전기"){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "초식" && this.attacker.move.type == "풀"){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "부유" && this.attacker.move.type == "땅"){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "흙먹기" && this.attacker.move.type == "땅"){
                invalidity = invalidity * 0
            }

            else if(this.defender.ability == "바람타기" && this.wind_list.includes(this.translateMove(this.attacker.move.name))){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "방음" && this.sound_list.includes(this.translateMove(this.attacker.move.name))){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "방탄" && this.bomb_list.includes(this.translateMove(this.attacker.move.name))){
                invalidity = invalidity * 0
            }

            else if(this.defender.ability == "불가사의부적" && ((this.type_modifier1 * this.type_modifier2) <= 2)){
                invalidity = invalidity * 0
            }

            else if(this.defender.ability == "흡반" && this.switch_list.includes(this.translateMove(this.attacker.move.name))){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "습기" && this.suicide_list.includes(this.translateMove(this.attacker.move.name))){
                invalidity = invalidity * 0
            }
            else if(this.defender.ability == "여왕의위엄" && this.strike_first_list.includes(this.translateMove(this.attacker.move.name))){
                invalidity = invalidity * 0
            }

            // # 도구보정
            if(this.defender.equipment == "풍선" && this.attacker.type == "땅"){
                invalidity = invalidity * 0
            }

            return invalidity
        },

        // # Mod1_defender
        mod1_defender(){
            let mod1 = 1

            // # 리플렉터
            if(this.defender && this.defender.support.reflect && !this.defender.support.aurora_veil && this.damage_class == "physical"){
                mod1 = mod1 * 0.5
            }

            // # 빛의장막
            if(this.defender && this.defender.support.light_screen && !this.defender.support.aurora_veil && this.damage_class == "special"){
                mod1 = mod1 * 0.5
            }

            // # 오로라베일
            if(this.defender && this.defender.support.aurora_veil && !this.defender.support.reflect && !this.defender.support.light_screen){
                mod1 = mod1 * 0.5
            }

            return mod1
        },

        // # Mod3_defender
        mod3_defender(){
            let mod3 = 1

            // # 상대특성보정
            if(this.defender && this.defender.ability == "하드록" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 0.75
            }
            else if(this.defender && this.defender.ability == "필터" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 0.75
            }
            else if(this.defender && this.defender.ability == "프리즘아머" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 0.75
            }

            // # 상대도구보정
            if(this.defender && this.defender.equipment == "반감열매"){
                if((this.type_modifier1 * this.type_modifier2) >= 2){
                    if(this.defender && this.defender.ability == "숙성"){
                        mod3 = mod3 * 0.5
                    }else{
                        mod3 = mod3 * 0.25
                    }
                }
            }

            return mod3
        },

        // # FHD 이상 반응형
        transformStyle() {
            let scale = 1
			// if (this.windowHeight >= 1200) {
			// 	// 1200px 이상부터 점진적으로 크기 증가
			// 	scale = 1 + (this.windowHeight - 1200) / 1200
			// }
			return {
				width: '820px',
				transform: `scale(${scale})`,
				transformOrigin: 'center top'
			}
        },
    },

    mounted(){
        // 포켓몬 목록 불러오기
        this.loadPokemonList()

        // 기술 목록 불러오기
        this.loadMoveList()

        // # FHD 이상 반응형
        this.$nextTick(() => {
			window.addEventListener('resize', this.handleResize)

            // 'ㄷ'자 제스처 감지 이벤트 리스너 추가
            // window.addEventListener('touchmove', this.isDShapeGesture)
		})

        window.addEventListener('keydown', this.handleKeyDown)

        this.$nextTick(() => {
            setTimeout(() => {
                this.adjustFooterPosition()
                const resizeObserver = new ResizeObserver(() => {
                    setTimeout(this.adjustFooterPosition, 100)
                })
                resizeObserver.observe(document.body)
            }, 100)
        })

        this.calculateSpeedValues()
    },

    // # FHD 이상 반응형
    beforeDestroy() {
		window.removeEventListener('resize', this.handleResize)

        // window.removeEventListener('touchmove', this.isDShapeGesture) // 이벤트 리스너 제거
	},

    methods: {
        handleDragStart(event) {
            // 북마크에 필요한 데이터 설정
            event.dataTransfer.setData('text/uri-list', window.location.href)
            event.dataTransfer.setData('text/plain', document.title)
        },

        check_attacker_stat(){
            // Mobile
            if(this.$vuetify.breakpoint.mobile){
                if(
                        this.attacker.name
                    &&
                        (this.$store.state.mobile_tab == 'attacker' || this.$store.state.mobile_tab == 'defender')
                    &&
                        this.attacker.name == this.$store.state.attacker.name
                ){
                    return true
                }

                else if(
                        this.attacker.name
                    &&
                        (this.$store.state.mobile_tab == 'speed_attacker' || this.$store.state.mobile_tab == 'speed_defender')
                    &&
                        this.attacker.name == this.$store.state.speed_attacker.name
                ){
                    return true
                }
            }

            // PC
            else if(!this.$vuetify.breakpoint.mobile){
                if(
                        this.attacker.name
                    &&
                        !this.$store.state.is_speed
                    &&
                        this.attacker.name == this.$store.state.attacker.name
                ){
                    return true
                }

                else if(
                        this.attacker.name
                    &&
                        this.$store.state.is_speed
                    &&
                        this.attacker.name == this.$store.state.speed_attacker.name
                ){
                    return true
                }  
            }
        },

        check_defender_stat(){
            // console.log("this.defender.name", this.defender.name)

            // Mobile
            if(this.$vuetify.breakpoint.mobile){
                if(
                        this.defender.name
                    &&
                        (this.$store.state.mobile_tab == 'attacker' || this.$store.state.mobile_tab == 'defender')
                    &&
                        this.defender.name == this.$store.state.defender.name
                ){
                    return true
                }

                else if(
                        this.defender.name
                    &&
                        (this.$store.state.mobile_tab == 'speed_attacker' || this.$store.state.mobile_tab == 'speed_defender')
                    &&
                        this.defender.name == this.$store.state.speed_defender.name
                ){
                    return true
                }
            }

            // PC
            else if(!this.$vuetify.breakpoint.mobile){
                if(
                        this.defender.name
                    &&
                        !this.$store.state.is_speed
                    &&
                        this.defender.name == this.$store.state.defender.name
                ){
                    return true
                }

                else if(
                        this.defender.name
                    &&
                        this.$store.state.is_speed
                    &&
                        this.defender.name == this.$store.state.speed_defender.name
                ){
                    return true
                }  
            }
        },

        // 'ㄷ'자 제스처를 감지하는 함수
        isDShapeGesture(event) {
            // 제스처의 경로를 분석하여 'ㄷ'자 모양인지 확인하는 로직을 구현
            // 이 부분은 제스처의 시작과 끝, 방향 등을 분석하여 구현해야 합니다.
            // 예시로 간단한 로직을 추가합니다.
            const { deltaX, deltaY } = event
            const isDShape = deltaX > 50 && deltaY > 50 // 간단한 예시

            // if (isDShape) {
            //     this.$store.commit("update_is_admin", !this.$store.state.is_admin)
            // }

            console.log(isDShape)

            return isDShape
        },
        
        findClosestSpeeds(targetSpeed, speed_stat, speed_individual, speed_effort, speed_personality) {
            // 파라미터를 통해 type 결정
            let type = ''
            if (speed_individual === 31 && speed_effort === 252 && speed_personality === 1.1) {
                type = '최속'
            } else if (speed_individual === 31 && speed_effort === 252 && speed_personality === 1.0) {
                type = '준속'
            } else if (speed_individual === 31 && speed_effort === 0 && speed_personality === 1.0) {
                type = '무보정'
            }

            // console.log(type)

            // 각 항목과 targetSpeed의 차이를 계산하여 배열로 만듭니다.
            const differences = this.caculated_stat_list.map(item => ({
                difference: item.value - targetSpeed,
                name: item.name,
                value: item.value,
                type: item.type,
                stat: item.base_stat,
                individual: item.speed_individual,
                effort: item.speed_effort,
                personality: item.speed_personality
            }))

            // console.log(differences)

            // 동일한 속성을 가진 항목 제외
            const filteredDifferences = differences.filter(item => 
                !(item.stat === speed_stat && 
                  item.individual === speed_individual && 
                  item.effort === speed_effort && 
                  item.personality === speed_personality)
            )

            const higher = []
            const lower = []

            // const filteredDifferencesReverse = filteredDifferences.slice().reverse();

            // 더 높은 값 2개, 동률인 것 1개, 더 낮은 값 2개를 선택합니다.
            filteredDifferences.filter(item => item.difference > 0)
                .sort((a, b) => a.difference - b.difference)
                // .slice(0, 2)
                // .forEach(item => {
                //     // if (higher.length < 2 && !higher.some(h => h.stat === item.stat)) {
                //     //     higher.push(item)
                //     // }
                //     if (higher.length < 2 && !higher.some(h => h.stat === item.stat || h.type === type)) {
                //         higher.push(item)
                //     }
                // })
                .forEach(item => {
                    if (higher.length < 2 && !higher.some(h => h.stat === item.stat) && item.type !== type) {
                        higher.push(item)
                    }
                })

            const equal = filteredDifferences.filter(item => item.difference === 0)
                .slice(0, 1)

            filteredDifferences.filter(item => item.difference < 0)
                .sort((a, b) => b.difference - a.difference)
                // .slice(0, 2)
                .forEach(item => {
                    if (lower.length < 2 && !lower.some(l => l.stat === item.stat)) {
                        lower.push(item)
                    }
                })

            const closestSpeeds = [...higher, ...equal, ...lower]
                .sort((a, b) => b.value - a.value)
                .map(item => {
                    return `${item.name} (${item.value}) ` + (item.value > targetSpeed ? '보다 느림' : item.value === targetSpeed ? '과 동률' : '을 추월')
                })

            return closestSpeeds
        },

        calculateSpeedValues() {
            const speedAttributes = [
                { type: '최속', individual: 31, effort: 252, personality: 1.1 },
                { type: '준속', individual: 31, effort: 252, personality: 1.0 },
                { type: '무보정', individual: 31, effort: 0, personality: 1.0 }
            ]
            const results = []

            this.stat_list.forEach(baseStat => {
                speedAttributes.forEach(attr => {
                    results.push({
                        "name": `${baseStat}족 ${attr.type}`,
                        "value": this.calculateSpeed(baseStat, attr.individual, attr.effort, attr.personality),
                        "type": attr.type,
                        "base_stat": baseStat,
                        "speed_individual": attr.individual,
                        "speed_effort": attr.effort,
                        "speed_personality": attr.personality
                    })
                })
            })

            // 결과값을 기준으로 내림차순 정렬
            this.caculated_stat_list = results.sort((a, b) => b.value - a.value)

            return results
        },

        calculateSpeed(baseStat, individualValue, effortValue, personality) {
            // [{(스피드종족값x2)+개체값+(노력치/4)}x레벨/100+5]x성격보정
            const level = 50 // 레벨은 50으로 고정
            return Math.floor(
                (
                    ((baseStat * 2) + individualValue + (effortValue / 4))
                    * (level / 100) + 5
                ) * personality
            )
        },

        handleResize: _.debounce(function() {
			this.windowWidth = window.innerWidth
			this.windowHeight = window.innerHeight
		}, 100), // 100ms 딜레이로 debounce 적용

        // 푸터 위치 조정
        adjustFooterPosition() {
            const footer = this.$refs.footer

            if(!footer) return

            if (window.innerHeight < document.body.scrollHeight) {
                footer.style.position = 'relative'
            } else {
                footer.style.position = 'fixed'
                footer.style.bottom = '0'
            }
        },

        handleKeyDown(event) {
            if (event.ctrlKey && event.altKey && event.key === 't') {
                this.showTestSheet = !this.showTestSheet
            }

            // if (event.ctrlKey && event.altKey && event.key === 's') {
            //     this.$store.commit("update_is_admin", !this.$store.state.is_admin)
            // }
        },

        // 포켓몬 목록 불러오기
        loadPokemonList() {
            this.$http.post("/api/pokemon/select")
            .then((res) => {
                this.pokemon_list = res.data
            })
        },

        // 기술 목록 불러오기
        loadMoveList() {
            this.$http.post("/api/move/select")
            .then((res) => {
                this.move_list = res.data
            })
        },

        // 포켓몬 번역
        translatePokemon(en) {
            const pokemon = this.pokemon_list.find(item => item.en === en)
            return pokemon ? pokemon.kr : en
        },

        // 기술 번역
        translateMove(en) {
            const move = this.move_list.find(item => item.en === en)
            return move ? move.kr : en
        },

        attacker_update_handler(attacker){
            this.attacker = attacker
        },

        defender_update_handler(defender){
            this.defender = defender
        },

        open_stat_handler(){
            this.dialog.stat = true
        },

        open_caculate_handler(){
            if(!this.$vuetify.breakpoint.mobile){
                if(!this.$store.state.is_speed){
                    if(this.attacker.name && this.attacker.move.name && this.defender.name){
                        this.dialog.caculate = true
                    }else{
                        alert("빈 값이 있어 계산을 할 수 없습니다.")
                    }
                }
                else{
                    if(this.attacker.name && this.defender.name){
                        this.dialog.speed_caculate = true
                    }else{
                        alert("빈 값이 있어 계산을 할 수 없습니다.")
                    }
                }
            }
            else{
                console.log(1)
                if(this.$store.state.mobile_tab != "speed_attacker" && this.$store.state.mobile_tab != "speed_defender"){
                    console.log(2)
                    if(this.attacker.name && this.attacker.move.name && this.defender.name){
                        this.dialog.caculate = true
                    }else{
                        alert("빈 값이 있어 계산을 할 수 없습니다.")
                    }
                }
                else{
                    console.log(3)
                    if(this.attacker.name && this.defender.name){
                        this.dialog.speed_caculate = true
                    }else{
                        alert("빈 값이 있어 계산을 할 수 없습니다.")
                    }
                }
            }
        },

        damage_class_update_handler(damage_class){
            this.damage_class = damage_class
        },

        // 최종 데미지
        final_damage(random){
            let final_damage = 0

            // ((레벨 X 2 / 5) + 2)
            final_damage = parseInt((this.attacker.level * 2 / 5) + 2)

            // X 위력
            final_damage = parseInt(final_damage * this.caculated_power)

            // X 공격력 (공격 실수값 X 각종 보정)
            final_damage = parseInt(final_damage * this.caculated_attack)

            // ÷ 50
            final_damage = parseInt(final_damage / 50)

            // ÷ 방어력 (방어 실수값 X 각종 보정)
            final_damage = parseInt(final_damage / this.caculated_defend)

            // * X Mod0 (mod1의 필드만 이동)
            final_damage = parseInt(final_damage * this.mod0)

            // + 2
            final_damage = parseInt(final_damage + 2)

            // X Mod1
            final_damage = parseInt(final_damage * this.mod1)

            // X 급소
            final_damage = parseInt(final_damage * this.gebso)

            // X 랜덤(0.85 ~ 1.00)
            final_damage = parseInt(final_damage * (random / 100))

            // X 자속보정
            final_damage = parseInt(final_damage * this.same_type_modifier)

            // X 타입상성1 (~2까지는 소수점 안버림)
            final_damage = final_damage * this.type_modifier1

            // X 타입상성2
            final_damage = parseInt(final_damage * this.type_modifier2)

            // X Mod3
            final_damage = parseInt(final_damage * this.mod3)

            // X Mod2
            final_damage = Math.round(final_damage * this.mod2 * 10) / 10

            // X 무효
            final_damage = parseInt(final_damage * this.invalidity)

            if(random == 85){
                this.$store.state.final_85 = final_damage
            }
            else if(random == 86){
                this.$store.state.final_86 = final_damage
            }
            else if(random == 87){
                this.$store.state.final_87 = final_damage
            }
            else if(random == 88){
                this.$store.state.final_88 = final_damage
            }
            else if(random == 89){
                this.$store.state.final_89 = final_damage
            }
            else if(random == 90){
                this.$store.state.final_90 = final_damage
            }
            else if(random == 91){
                this.$store.state.final_91 = final_damage
            }
            else if(random == 92){
                this.$store.state.final_92 = final_damage
            }
            else if(random == 93){
                this.$store.state.final_93 = final_damage
            }
            else if(random == 94){
                this.$store.state.final_94 = final_damage
            }
            else if(random == 95){
                this.$store.state.final_95 = final_damage
            }
            else if(random == 96){
                this.$store.state.final_96 = final_damage
            }
            else if(random == 97){
                this.$store.state.final_97 = final_damage
            }
            else if(random == 98){
                this.$store.state.final_98 = final_damage
            }
            else if(random == 99){
                this.$store.state.final_99 = final_damage
            }
            else if(random == 100){
                this.$store.state.final_100 = final_damage
            }

            return final_damage
        },

        final_final(){
            let x = Math.ceil(100 / ((this.final_damage(85) / this.hp_modifier).toFixed(3) * 100).toFixed(1))
            let y = Math.ceil(100 / ((this.final_damage(100) / this.hp_modifier).toFixed(3) * 100).toFixed(1))

            if(x == y){
                this.$store.state.final_final = "확정 " + y + "타"
                return "확정 " + y + "타"
            }
            else{
                // 확률 계산 추가
                let successCount = 0
                for (let i = 85; i <= 100; i++) {
                    if (Math.ceil(100 / ((this.final_damage(i) / this.hp_modifier).toFixed(3) * 100).toFixed(1)) === y) {
                        successCount++
                    }
                }
                let probability = (successCount / 16) * 100 // 85부터 100까지 16개의 값
                this.$store.state.final_final = `난수 ${y}타 (${probability.toFixed(2)}%)`
                return `난수 ${y}타 (${probability.toFixed(2)}%)`
            }
        },

        // Attacker > 포켓몬 타입 2개 이상 선택시 맨 앞 삭제
        attacker_types_updated(){
            if(this.attacker.types.length > 2){
                this.attacker.types.shift()
            }
        },

        // Attacker > 포켓몬 타입 삭제
        removeAttackerType(index) {
            this.attacker.types.splice(index, 1)
        },

        // Defender > 포켓몬 타입 2개 이상 선택시 맨 앞 삭제
        defender_types_updated(){
            if(this.defender.types.length > 2){
                this.defender.types.shift()
            }
        },

        // Defender > 포켓몬 타입 삭제
        removeDefenderType(index) {
            this.defender.types.splice(index, 1)
        },

        openLink(url) {
            window.open(url, '_blank')
        },

        // 스텟수정창 -> Attacker 리셋 버튼
        loadAttacker(){
            this.loading = true

            // pokemon_list에서 해당 포켓몬 찾기
            const pokemon = this.pokemon_list.find(p => p.en === this.attacker.name)
            
            if (pokemon) {
                // 포켓몬 이미지
                this.attacker.sprite = pokemon.sprite

                // 포켓몬 타입
                this.attacker.types = []
                if (pokemon.type1) this.attacker.types.push(pokemon.type1)
                if (pokemon.type2) this.attacker.types.push(pokemon.type2)

                // 포켓몬 스탯
                this.attacker.stats.hp = pokemon.h
                this.attacker.stats.attack = pokemon.a
                this.attacker.stats.defense = pokemon.b
                this.attacker.stats.special_attack = pokemon.c
                this.attacker.stats.special_defense = pokemon.d
                this.attacker.stats.speed = pokemon.s

                // 공격력
                this.attacker.damage = this.attacker.move.damage_class == "physical"? 
                    this.attacker.stats.attack : this.attacker.stats.special_attack
            }

            this.loading = false

            // this.$http.get("https://pokeapi.co/api/v2/pokemon/"+this.attacker.name)
            // .then(async(res) => {
            //     // 포켓몬 이미지
            //     this.attacker.sprite = res.data.sprites.front_default

            //     // 포켓몬 타입
            //     this.attacker.types = []
            //     await res.data.types.forEach(type => {
            //         this.$http.get(type.type.url)
            //         .then(res => {
            //             this.attacker.types.push(res.data.names[1].name)
            //         })
            //     })

            //     // 포켓몬 스탯
            //     this.attacker.stats.hp = res.data.stats[0].base_stat
            //     this.attacker.stats.attack = res.data.stats[1].base_stat
            //     this.attacker.stats.defense = res.data.stats[2].base_stat
            //     this.attacker.stats.special_attack = res.data.stats[3].base_stat
            //     this.attacker.stats.special_defense = res.data.stats[4].base_stat
            //     this.attacker.stats.speed = res.data.stats[5].base_stat

            //     // 공격력
            //     this.attacker.damage = this.attacker.move.damage_class == "physical"? this.attacker.stats.attack : this.attacker.stats.special_attack

            //     this.loading = false
            // })
        },

        // 스텟수정창 -> Defender 리셋 버튼
        loadDefender(){
            this.loading = true

            // pokemon_list에서 해당 포켓몬 찾기
            const pokemon = this.pokemon_list.find(p => p.en === this.defender.name)
            
            if (pokemon) {
                // 포켓몬 이미지
                this.defender.sprite = pokemon.sprite

                // 포켓몬 타입
                this.defender.types = []
                if (pokemon.type1) this.defender.types.push(pokemon.type1)
                if (pokemon.type2) this.defender.types.push(pokemon.type2)

                // 포켓몬 스탯
                this.defender.stats.hp = pokemon.h
                this.defender.stats.attack = pokemon.a
                this.defender.stats.defense = pokemon.b
                this.defender.stats.special_attack = pokemon.c
                this.defender.stats.special_defense = pokemon.d
                this.defender.stats.speed = pokemon.s

                // 공격력
                this.defender.damage = this.defender.move.damage_class == "physical"? 
                    this.defender.stats.attack : this.defender.stats.special_attack
            }

            this.loading = false

            // this.$http.get("https://pokeapi.co/api/v2/pokemon/"+this.defender.name)
            // .then(async(res) => {
            //     // 포켓몬 이미지
            //     this.defender.sprite = res.data.sprites.front_default

            //     // 포켓몬 타입
            //     this.defender.types = []
            //     await res.data.types.forEach(type => {
            //         this.$http.get(type.type.url)
            //         .then(res => {
            //             this.defender.types.push(res.data.names[1].name)
            //         })
            //     })

            //     // 포켓몬 스탯
            //     this.defender.stats.hp = res.data.stats[0].base_stat
            //     this.defender.stats.attack = res.data.stats[1].base_stat
            //     this.defender.stats.defense = res.data.stats[2].base_stat
            //     this.defender.stats.special_attack = res.data.stats[3].base_stat
            //     this.defender.stats.special_defense = res.data.stats[4].base_stat
            //     this.defender.stats.speed = res.data.stats[5].base_stat

            //     this.loading = false
            // })
        },

        // 교체
        switch_pokemon(){
            if(!this.attacker.name || !this.defender.name){
                alert("양쪽의 포켓몬이 선택되어 있지 않습니다")
                return
            }

            let temp = this.attacker.name
            this.attacker.name = this.defender.name
            this.defender.name = temp

            this.loadAttacker()
            this.loadDefender()
        },

        // 초기화
        reset_data() {
            // 기존 속성 임시 저장
            const attackerBackup = {
                stats: { ...this.attacker.stats },
                sprite: this.attacker.sprite,
                name: this.attacker.name,
                types: Array.isArray(this.attacker.types) ? [...this.attacker.types] : [] // types가 배열인지 확인
            }
            
            const defenderBackup = {
                stats: { ...this.defender.stats },
                sprite: this.defender.sprite,
                name: this.defender.name,
                types: Array.isArray(this.defender.types) ? [...this.defender.types] : [] // types가 배열인지 확인
            }

            // 초기화
            this.attacker = JSON.parse(JSON.stringify(this.initialState.attacker))
            this.defender = JSON.parse(JSON.stringify(this.initialState.defender))

            // Vue의 nextTick을 사용하여 DOM 업데이트 후 속성 복원
            this.$nextTick(() => {
                // 백업한 속성 복원
                this.attacker = {
                    ...this.attacker,
                    stats: attackerBackup.stats,
                    sprite: attackerBackup.sprite,
                    name: attackerBackup.name,
                    types: attackerBackup.types
                }
                
                this.defender = {
                    ...this.defender,
                    stats: defenderBackup.stats,
                    sprite: defenderBackup.sprite,
                    name: defenderBackup.name,
                    types: defenderBackup.types
                }

                this.key += 1
            })
        },

        handleTouchStart(event) {
            this.startX = event.touches[0].clientX
            this.endX = this.startX // * 터치 시작 시 endX를 startX로 초기화 (이게 핵심이였네)

            this.startY = event.touches[0].clientY
            this.endY = this.startY

            // this.start_scroll_y = window.scrollY
            this.start_scroll_y = document.documentElement.scrollTop
        },

        handleTouchMove(event) {
            this.endX = event.touches[0].clientX

            this.endY = event.touches[0].clientY
        },

        handleTouchEnd() {
            const deltaX = this.endX - this.startX
            // console.log("endX", this.endX)
            // console.log("startX", this.startX)
            if (Math.abs(deltaX) > 100) {
                if (deltaX > 100 && this.$store.state.mobile_tab === 'defender')
                {
                    this.$store.commit('update_mobile_tab', 'attacker')
                }
                else if (deltaX < -100 && this.$store.state.mobile_tab === 'attacker')
                {
                    this.$store.commit('update_mobile_tab', 'defender')
                }
                else if (deltaX > 100 && this.$store.state.mobile_tab === 'speed_defender')
                {
                    this.$store.commit('update_mobile_tab', 'speed_attacker')
                }
                else if (deltaX < -100 && this.$store.state.mobile_tab === 'speed_attacker')
                {
                    this.$store.commit('update_mobile_tab', 'speed_defender')
                }
                else if (deltaX > 100 && this.$store.state.mobile_tab === 'docs_right')
                {
                    this.docs_pokemon = this.$store.state.docs_pokemon
                    this.$store.commit('update_mobile_tab', 'docs_left')
                }
                else if (deltaX < -100 && this.$store.state.mobile_tab === 'docs_left')
                {
                    this.docs_pokemon = this.$store.state.docs_pokemon
                    this.$store.commit('update_mobile_tab', 'docs_right')
                }
            }

            const deltaY = this.endY - this.startY
            if (deltaY > 400 && this.start_scroll_y === 0) {
                this.reset_data()
            }

            // 'ㄷ'자 제스처 감지
            // const isDShape = deltaX > 50 && deltaY > 50

            // if (isDShape) {
            //     this.$store.commit("update_is_admin", !this.$store.state.is_admin)
            // }


            this.startX = 0
            this.endX = 0
            this.start_scroll_y = 0
        }
    }
}
</script>
<style scoped>
.footer {
    width: 100%;
    background-color: transparent;
}

/* v-text-field, v-autocomplete > 라벨 글자 */
::v-deep .center_label .v-text-field .v-label {
    color: #272727;
    font-size: 10px;
    line-height:16px;
}

/* v-text-field, v-autocomplete > 입력값 글자 */
::v-deep .center_label .v-text-field input {
    color: #626262 !important;
    font-size:12px;
}

/* v-text-field, v-select, v-autocomplete > 전체 높이 지정 */
::v-deep .center_label .v-text-field .v-input__slot { 
    height: 50px;
}

/* v-text-field, v-select, v-autocomplete > 전체 높이 지정 */
::v-deep .mobile_stat .v-text-field .v-input__slot { 
    height: 46px !important;
}


</style>
<style scoped>
::v-deep .center_label legend {
    text-align: center !important;
    width:40px;
}

::v-deep .center_label .v-text-field .v-label {
    left: 50% !important;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    color: #888888 !important;
    font-size: 9px !important;
    line-height:20px !important;
}

/* v-text-field, v-autocomplete > 입력값 글자 */
::v-deep .center_label .v-text-field input {
    color: #626262 !important;
    font-size:14px !important;
}

::v-deep .center_label .v-text-field .v-label--active {
    transform: translateY(-18px) translateX(-50%);
    padding:0 4px;
    background:white;
}

/* v-text-field, v-autocomplete > 입력값 글자 */
::v-deep .mobile_stat .v-text-field input {
    color: #626262 !important;
    font-size:12px !important;
}

</style>